<template>
  <bulk-action-modal @confirmed="save()" @closed="$emit('closed')">
    <template v-slot:title>Create Items</template>

    <template v-slot:content>
      <div class="create-items-modal-container">
        <div class="item-count">
          <label>Item Count <span class="optional">( max 30 )</span></label>
          <input v-model="itemCount" type="number" min=1 max=30 />
        </div>
        <div class="item-type">
          <label>Item Type</label>
          <select
            v-model="itemType"
            name="item-type"
            class="item-type-selection"
          >
            <option value="null">
              Select Item Type
            </option>
            <option
              v-for="(itemType, index) in itemTypes"
              :key="index"
              :value="itemType"
            >
              {{ itemType }}
            </option>
          </select>
        </div>
        <div class="product-select">
          <label>Select Product</label>
          <hlt-multiselect
            v-if="availableApps"
            ref="multiselect1"
            v-model="app"
            class="pa-product-select"
            :options="availableApps"
            :multiple="false"
            :show-labels="false"
            track-by="id"
            label="internal_name"
            placeholder="Select a Product"
            :disabled="!canWriteProductAssociations"
          />
        </div>
        <div class="optional-container" v-if="showOptionalFields">
          <div class="assignments-container">
            <label>Assign to <span class="optional">( optional )</span></label>

            <hlt-multiselect
              ref="multiselect"
              v-model="assignTo"
              track-by="id"
              label="full_name"
              class="assign-input"
              :options="cmsUsers"
              :multiple="false"
              :searchable="true"
              :max-height="150"
              :show-no-results="true"
              :allow-empty="true"
              :clear-on-select="false"
              :show-labels="false"
              placeholder="Select Assignee"
            >
            </hlt-multiselect>

            <label>Reviewer <span class="optional">( optional )</span></label>

            <hlt-multiselect
              ref="multiselect2"
              v-model="reviewer"
              track-by="id"
              label="full_name"
              class="reviewer-input"
              :options="cmsUsers"
              :multiple="false"
              :searchable="true"
              :max-height="150"
              :show-no-results="true"
              :allow-empty="true"
              :clear-on-select="false"
              :show-labels="false"
              placeholder="Select Reviewer"
            >
            </hlt-multiselect>
          </div>

        </div>
      </div>
    </template>
  </bulk-action-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import railsRoutes from '@/store/rails-routes.js'
import axios from 'axios'
import ProductAssociation from '../shared/ProductAssociation.vue'
import BulkActionModal from '@/components/assets/shared/bulk_actions/BulkActionModal.vue'
import BulkActionsMixin from '@/mixins/bulk-actions.js'
import HLTMultiselect from '../../shared/VueMultiSelect.vue'

export default {
  components: {
    'hlt-multiselect': HLTMultiselect,
    ProductAssociation,
    BulkActionModal,
    HLTMultiselect
  },
  mixins: [BulkActionsMixin],
  data () {
    return {
      saving: false,
      itemCount: 1,
      assignTo: null,
      reviewer: null,
      app: null,
      itemType: null,
      itemTypes: [
        'Blank Text Completion',
        'Bowtie',
        'Cloze / Dropdown',
        'Date Picker',
        'Drag and Drop',
        'Flipcard',
        'Fractional Numeric Entry',
        'Highlight',
        'Hotspot',
        'Matrix Grid',
        'Multiple Choice',
        'Numeric Entry',
        'Ordered Response',
        'SATA'
      ]
    }
  },
  computed: {
    ...mapGetters([
      'assetType',
      'currentUser',
      'cmsUsers',
      'apps'
    ]),
    availableApps () {
      let as = this.currentUser.apps
      let asIds = this.currentUser.apps.map(x => x.id)
      return as.concat(this.apps
        .filter(k => asIds.indexOf(k.id) === -1)
        .filter(l => l.internal_name !== undefined))
        .sort((a, b) => {
          return (a.internal_name > b.internal_name) - (a.internal_name < b.internal_name)
        })
    },
    canWriteProductAssociations () {
      if (this.$router.isRoute('item')) {
        return this.currentUser.permissions.can_write_items_product_associations
      } else {
        return false
      }
    },
    showOptionalFields () {
      let types = [
        'Multiple Choice',
        'Cloze / Dropdown',
        'SATA',
        'Bowtie'
      ]
      return types.includes(this.itemType) &&
             (this.currentUser.permissions.is_a_asset_assigner || this.currentUser.is_admin)
    }
  },
  methods: {
    findAsset () {
      return { product_associations: this.associations }
    },
    save () {
      if (!this.saving) {
        // Disable save button so it can't be pressed multiple times
        this.saving = true
        let url = railsRoutes.api_path({ type: this.assetType + 's', action: 'batch_create' })

        let params = {
          asset_count: this.itemCount,
          app_id: this.app?.id,
          type_variant: this.itemType,
          reviewer_id: this.reviewer?.id,
          assign_to_id: this.assignTo?.id
        }

        axios.post(url, params).then(response => {
          this.saving = false
          let name = this.assetType.charAt(0).toUpperCase() + this.assetType.slice(1)
          const msg = `${name} Creation Started...`
          this.$modal.hide('bulk-action-modal')
          this.$notify({ title: msg, type: 'success' })
        })
      }
    },
    clearModalData () {
      this.saving = false
      this.associations = []
    }
  }
}

</script>
<style lang="scss" scoped>
.create-items-modal-container {
  margin: 0;
  padding: 20px;
  min-height: 500px;
  height: auto;
  overflow: scroll;

  font-weight: 400;
  .button {
    color: $white-2;
  }

  > label {
    font-weight: 700;
    margin-bottom: 8px;
    font-size: 18px;
  }
  select {
    color: $space-gray;
    font-size: 14px;
    letter-spacing: 1px;
  }
  .item-count, .item-type {
    width: 50%;
  }
  .optional-container {
    font-size: 1rem;
    width: 50%;
    .optional {
      color: $space-gray !important;
    }
  }

}
</style>
