<template>
  <div class="inner-content">
    <div class="flex-row search-row-section">
      <div class="flex-row-item flex-bulk-actions">
        <asset-bulk-actions />
      </div>
      <div class="flex-row-item flex-search-input">
        <search-input />
      </div>
    </div>
    <div class="main-table-container">
      <search-results />
    </div>

    <delete-confirmation>
      <template slot="name">
        <div v-html="asset.question" />
      </template>
      <template slot="body">
        {{ appsCount }} apps use this topic.
      </template>
    </delete-confirmation>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import AssetBulkActions from '../shared/BulkActions.vue'
import SearchInput from '../shared/SearchInput.vue'
import SearchResults from '../../shared/SearchResults.vue'
import DeleteConfirmation from '../shared/DeleteConfirmation.vue'
import searchKeys from '../../../mixins/search-keys.js'

export default {
  components: { AssetBulkActions, SearchInput, SearchResults, DeleteConfirmation },
  mixins: [searchKeys],
  data () {
    return {
      itemTypes: [
        'Multiple Choice',
        'Blank Text Completion',
        'Numeric Entry',
        'Fractional Numeric Entry',
        'Flipcard',
        'Ordered Response',
        'Date Picker',
        'Matrix Grid',
        'Hotspot',
        'Cloze / Dropdown',
        'SATA',
        'Bowtie',
        'Drag and Drop',
        'Highlight'
      ]
    }
  },
  computed: {
    appsCount () {
      if (this.asset.id) {
        return this.asset.apps.length
      } else {
        return 'unknown'
      }
    },
    ...mapGetters([
      'asset',
      'tags',
      'contentSources'
    ])
  },
  async created () {
    this.setAssetType({ assetType: 'item' })
    await this.getTags()
    await this.getContentSources()
    const keys = [
      { key: 'ids', display_name: 'IDs', type: 'text' },
      { key: 'products', display_name: 'Product', type: 'product' },
      { key: 'category_type_ids', display_name: 'Category Type', type: 'category_type' },
      { key: 'question', display_name: 'Stem', type: 'text' },
      { key: 'rationale', display_name: 'Explanation', type: 'text' },
      { key: 'answers_raw_content', display_name: 'Choices', type: 'text' },
      { key: 'answers_raw_rationale', display_name: 'CSR', type: 'text' },
      { key: 'type', display_name: 'Item Type', type: 'multiple_autocomplete', options: this.itemTypes.sort().map(x => { return { name: x } }), trackBy: 'name', label: 'name' },
      { key: 'has_case', display_name: 'Has Case', type: 'boolean' },
      { key: 'number_of_choices', display_name: 'Number of Choices', type: 'text' },
      { key: 'content_notes', display_name: 'Content Notes', type: 'text' },
      { key: 'content_reference', display_name: 'Content Reference', type: 'text' },
      { key: 'content_source_ids', display_name: 'Content Source', type: 'multiple_autocomplete', options: this.contentSources, trackBy: 'id', label: 'name' },
      { key: 'tags', display_name: 'Tags', type: 'multiple_autocomplete', options: this.tags, trackBy: 'name', label: 'name' },
      { key: 'created_at', display_name: 'Created At', type: 'date_range' },
      { key: 'updated_at', display_name: 'Updated At', type: 'date_range' },
      { key: 'revised_at', display_name: 'Revised At', type: 'date_range' }
    ]
    this.setAvailableKeys(keys)
  },
  methods: {
    close () {
      this.$modal.hide('metadata-modal')
    },
    ...mapMutations([
      'setAssetType'
    ]),
    ...mapActions([
      'getTags',
      'getContentSources'
    ])
  }
}
</script>
