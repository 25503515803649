<template>
  <bulk-action-modal @confirmed="save()" @closed="$emit('closed')">
    <template v-slot:title>Add Tags</template>

    <template v-slot:appliesTo>
      <span>Applies to {{ assetIds.length }} {{ assetType }}<span v-if="assetIds.length > 1">s</span></span>
    </template>

    <template v-slot:content>
      <div class="tag-modal-container">
        <div
          v-if="currentUser.permissions.can_read_tags"
          class="tags"
        >
          <label for="tag-select">Selects Tags To Add</label>

          <tag-multiselect
            v-if="tags"
            ref="multiselect"
            v-model="selectedTags"
            class="tag-section"
            track-by="name"
            label="name"
            :options="tags"
            :multiple="true"
            :show-labels="false"
            :searchable="true"
            open-direction="bottom"
            tag-position="bottom"
            :max-height="150"
            placeholder="Please select a tag"
            :disabled="!canWriteToAsset || !currentUser.permissions.can_read_tags"
          />
        </div>
      </div>
    </template>
  </bulk-action-modal>
</template>

<script>
import railsRoutes from '../../../../store/rails-routes'
import axios from 'axios'
import TagMultiselect from '@/components/shared/TagMultiSelect'
import { mapGetters, mapActions } from 'vuex'
import BulkActionModal from '@/components/assets/shared/bulk_actions/BulkActionModal.vue'
import BulkActionsMixin from '@/mixins/bulk-actions.js'

export default {
  components: {
    TagMultiselect,
    BulkActionModal
  },
  mixins: [BulkActionsMixin],
  data () {
    return {
      saving: false,
      selectedTags: []
    }
  },
  computed: {
    ...mapGetters([
      'assetType',
      'currentUser',
      'tags',
      'canWriteToAsset'
    ])
  },
  created () {
    if (this.currentUser.permissions.can_read_tags && !this.tags) {
      this.getTags()
    }
  },
  methods: {
    ...mapActions([
      'getTags'
    ]),
    save () {
      if (!this.saving) {
        // Disable save button so it can't be pressed multiple times
        this.saving = true
        var url = railsRoutes.api_path({ type: this.assetType + 's', action: 'batch_add_tag' })

        var params = {
          asset_ids: this.assetIds,
          tag_names: this.selectedTags.map(t => t.name)
        }
        axios.post(url, params).then(response => {
          this.saving = false

          this.$modal.hide('bulk-action-modal')
          this.$notify({ title: 'Bulk Add Tag Job Started', type: 'success' })
        })
      }
    }
  },
  mounted () {
    this.updateAssetIds()
  }
}

</script>
<style lang="scss">
.tag-modal-container {
  margin: 0;
  padding: 20px;
  min-height: 300px;
  background-color: #FCFBFC;
  height: auto;
  .button {
    color: white;
  }

  > label {
    font-weight: 700;
    margin-bottom: 8px;
    font-size: 18px;
  }
  > select {
    width: 75%;
  }
  .tag-section {
    margin-bottom: 35px;
  }
  .multiselect__content-wrapper {
    z-index: 999;
  }

}
</style>
