<template>
  <div class="flex-table-row content-source-result-view">
    <div class="flex-table-cell">
      <div class="result-data result-checkbox">
        <input
          :id="'checkbox' + result.id"
          v-model="result.selected"
          class="asset_id"
          name="content_source_id"
          type="checkbox"
          :value="result.id"
        >
        <label
          class="checkbox-label"
          :for="'checkbox' + result.id"
        />
      </div>
    </div>
    <div class="flex-table-cell result-id">
      <div class="result-data-id result-data">
        {{ result.id }}
      </div>
    </div>
    <div class="flex-table-cell result-name">
      <div class="result-data">
        <div
          class="content-source-name"
          v-html="highlight_search_text(result.name, 'name')"
        />
      </div>
    </div>
    <div class="flex-table-cell">
      <div class="result-data action-button-cell">
        <div class="button-container">
          <button
            class="hlt-button secondary hlt-small-button edit-content-source"
            @click="editRecord(result)"
          >
            Edit
          </button>
          <button
            class="hlt-button warning hlt-small-button delete-content-source"
            @click="deleteRecord(result)"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import railsRoutes from '../../../store/rails-routes'
import highlighter from '../../../mixins/highlighter.js'

export default {
  mixins: [
    highlighter
  ],
  props: ['result'],
  computed: {
    ...mapGetters([
      'asset',
      'deleteAsset'
    ])
  },
  watch: {
    deleteAsset (val, oldVal) {
      if ((val === true) && (this.asset.id == this.result.id)) {
        this.delete({ record: this.asset, url: railsRoutes.api_path({ type: 'content_sources' }) }).then(response => {
          this.$modal.hide('delete-modal')
          this.setDeleteAsset({ value: false })
          this.resetSearchResults()
          this.searching()
        })
      }
    }
  },
  methods: {
    editRecord (record) {
      this.setAsset({ asset: record })
      this.$modal.show('edit-content-source-modal')
    },
    deleteRecord (record) {
      this.setAsset({ asset: record })
      if (this.$modal) {
        this.$modal.show('delete-modal')
      }
    },
    ...mapMutations([
      'setAsset',
      'resetSearchResults',
      'setDeleteAsset'
    ]),
    ...mapActions([
      'delete',
      'searching',
    ])
  }
}
</script>
<style lang="scss">
.content-source-result-view {
  .action-button-cell {
    button {
      margin-bottom: 10px;
    }
  }
  .content-source-name {
    display: inline;
  }
}
</style>
