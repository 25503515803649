<template>
  <div class="asset-bulk-actions-container">
    <ul
      v-if="hasAccess"
      class="menu submenu is-dropdown-submenu first-sub vertical showActionMenu"
    >
      <li
        v-if="currentUser.permissions.can_delete_groupings"
        class="is-submenu-item is-dropdown-submenu-item actionSubMenuTrigger actionDeleteTrigger"
      >
        <a
          id="show-action"
          @click="selectAction('deleteModal')"
        >Delete Selected</a>
      </li>
      <li
        v-if="currentUser.permissions.can_overwrite_live_groupings"
        class="is-submenu-item is-dropdown-submenu-item actionSubMenuTrigger actionOverwriteLiveTrigger"
      >
        <a @click="selectAction('overwriteLiveModal')">Overwrite Live</a>
      </li>
      <li
        v-if="currentUser.permissions.can_write_groupings"
        class="is-submenu-item is-dropdown-submenu-item actionSubMenuTrigger actionChangeStateTrigger"
      >
        <a @click="selectAction('changeStateModal')">Change State</a>
      </li>
      <li
        v-if="currentUser.permissions.can_write_groupings"
        class="is-submenu-item is-dropdown-submenu-item actionSubMenuTrigger actionBatchAddTagTrigger"
      >
        <a @click="selectAction('addTagsModal')">Add Tags</a>
      </li>
      <li
        v-if="currentUser.permissions.can_write_groupings"
        class="is-submenu-item is-dropdown-submenu-item actionSubMenuTrigger actionBatchRemoveTagTrigger"
      >
        <a @click="selectAction('removeTagsModal')">Remove Tags</a>
      </li>
      <li
        v-if="currentUser.permissions.can_write_groupings"
        class="is-submenu-item is-dropdown-submenu-item actionSubMenuTrigger actionBatchRevisedDateTrigger"
      >
        <a @click="selectAction('revisedDateModal')">Update Revised Date</a>
      </li>
    </ul>
    <delete-modal v-if="selectedAction == 'deleteModal'"  @closed="closedModal" />
    <overwrite-live-modal v-if="selectedAction == 'overwriteLiveModal'"  @closed="closedModal" />
    <change-state-modal v-if="selectedAction == 'changeStateModal'"  @closed="closedModal" />
    <add-tag-modal v-if="selectedAction == 'addTagsModal'"  @closed="closedModal" />
    <remove-tag-modal v-if="selectedAction == 'removeTagsModal'"  @closed="closedModal" />
    <change-revised-date-modal v-if="selectedAction == 'revisedDateModal'"  @closed="closedModal" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DeleteModal from '../shared/bulk_actions/DeleteAssetsModal.vue'
import OverwriteLiveModal from '../shared/bulk_actions/OverwriteLiveModal.vue'
import ChangeStateModal from '../shared/bulk_actions/ChangeStateModal.vue'
import BulkActionsMixin from '@/mixins/bulk-actions.js'
import AddTagModal from '../shared/bulk_actions/AddTagModal.vue'
import RemoveTagModal from '../shared/bulk_actions/RemoveTagModal.vue'
import ChangeRevisedDateModal from '../shared/bulk_actions/ChangeRevisedDateModal.vue'

export default {
  components: {
    DeleteModal,
    OverwriteLiveModal,
    ChangeStateModal,
    AddTagModal,
    RemoveTagModal,
    ChangeRevisedDateModal
  },
  mixins: [BulkActionsMixin],
  computed: {
    ...mapGetters([
      'currentUser'
    ]),
    hasAccess () {
      return this.currentUser.permissions.can_write_groupings ||
             this.currentUser.permissions.can_delete_groupings ||
             this.currentUser.permissions.can_overwrite_live_groupings
    }

  }
}
</script>

<style lang="scss" scoped>
.is-dropdown-submenu {
  width: 250px;
}
</style>
