<template>
  <div class="flex-header-section">
    <div class="flex-header result-data-id">
      <span>ID</span>
    </div>
    <div class="flex-header">
      <span>Photo</span>
    </div>
    <div class="flex-header">
      <span>Name</span>
    </div>
    <div class="flex-header">
      <span>Email</span>
    </div>

    <div class="flex-header">
      <span>Admin?</span>
    </div>

    <div class="flex-header">
      <span>Actions</span>
    </div>
  </div>
</template>

<script>
import SelectAllSearchHeader from '../../assets/shared/SelectAllSearchHeader'

export default {
  components: { SelectAllSearchHeader }
}
</script>
