<template>
  <div class="flex-header-section">
    <select-all-search-header class="select-all flex-header main-checkbox" />
    <div class="flex-header">
      <span>Asset ID</span>
    </div>
    <div class="flex-header">
      <span>Asset Type</span>
    </div>
    <div class="flex-header" v-if="isManager">
      <span>Assigned To</span>
    </div>
    <div class="flex-header" v-if="isManager">
      <span>Reviewer</span>
    </div>
    <div class="flex-header">
      <span>Preview</span>
    </div>
    <div class="flex-header">
      <span></span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SelectAllSearchHeader from '../../assets/shared/SelectAllSearchHeader'

export default {
  components: { SelectAllSearchHeader },
  computed: {
    ...mapGetters([
      'currentUser'
    ]),
    isManager () {
      return this.currentUser.permissions.is_a_asset_assigner
    }
  },
}
</script>
