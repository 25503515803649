<template>
  <bulk-action-modal @confirmed="save()" @closed="$emit('closed')">
    <template v-slot:title>Reassignment</template>

    <template v-slot:appliesTo>
      <span>Applies to {{ assetIds.length }} assignment<span v-if="assetIds.length > 1">s</span></span>
    </template>

    <template v-slot:content>
      <div class="reassignment-modal-container">
        <div>
          <label>Assigned To</label>

          <hlt-multiselect
            ref="multiselect"
            v-model="assignTo"
            track-by="id"
            label="full_name"
            class="assign-input"
            :options="cmsUsers"
            :multiple="false"
            :searchable="true"
            :max-height="150"
            :show-no-results="true"
            :allow-empty="true"
            :clear-on-select="false"
            :show-labels="false"
            placeholder="Select Assignee"
          >
          </hlt-multiselect>

        </div>
        <div>
          <label>Reviewer</label>
          <hlt-multiselect
            ref="multiselect2"
            v-model="reviewer"
            track-by="id"
            label="full_name"
            class="reviewer-input"
            :options="cmsUsers"
            :multiple="false"
            :searchable="true"
            :max-height="150"
            :show-no-results="true"
            :allow-empty="true"
            :clear-on-select="false"
            :show-labels="false"
            placeholder="Select Reviewer"
          >
          </hlt-multiselect>

        </div>
      </div>
    </template>
  </bulk-action-modal>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import railsRoutes from '@/store/rails-routes.js'
import axios from 'axios'
import HLTMultiselect from '../../shared/VueMultiSelect'
import BulkActionModal from '@/components/assets/shared/bulk_actions/BulkActionModal.vue'
import BulkActionsMixin from '@/mixins/bulk-actions.js'

export default {
  components: {
    'hlt-multiselect': HLTMultiselect,
    BulkActionModal
  },
  mixins: [BulkActionsMixin],
  data () {
    return {
      saving: false,
      assignTo: null,
      reviewer: null
    }
  },
  computed: {
    ...mapGetters([
      'selectAll',
      'searchResults',
      'cmsUsers',
      'currentUser'
    ])
  },
  created () {
    if (this.currentUser.permissions.is_a_asset_assigner) {
      axios.get(railsRoutes.api_path({ type: 'admin_users' })).then(response => {
        this.setCmsUsers({ cmsUsers: response.data.records })
      })
    }
  },
  methods: {
    ...mapMutations([
      'setCmsUsers'
    ]),
    save () {
      if (!this.saving) {
        // Disable save button so it can't be pressed multiple times
        this.saving = true
        var url = railsRoutes.api_path({ type: 'asset_assignments', action: 'batch_update' })
        const assetAssignments = this.assetIds.map(x => {
          return { id: x }
        })

        var params = {
          asset_assignments: assetAssignments,
          reviewer_admin_user_id: this.reviewer?.id,
          assigned_to_admin_user_id: this.assignTo?.id
        }

        axios.put(url, params).then(response => {
          this.saving = false
          this.$modal.hide('bulk-action-modal')
          this.$notify({ title: 'Reassignment Started', type: 'info' })
        })
      }
    }
  },
  mounted () {
    this.updateAssetIds()
  }
}

</script>
<style lang="scss">
.reassignment-modal-container {
  background-color: #FCFBFC;
  min-height: 500px;
  height: auto;
  padding: 20px;
  padding-right: 60px;

  .button {
    color: white;
  }

  > label {
    font-weight: 700;
    margin-bottom: 8px;
    font-size: 18px;
  }
}
</style>
