<template>
  <bulk-action-modal @confirmed="overwriteLive()" @closed="$emit('closed')">
    <template v-slot:title>Overwrite Live</template>

    <template v-slot:appliesTo>
      <span>Applies to {{ assetIds.length }} {{ assetType }}<span v-if="assetIds.length > 1">s</span></span>
    </template>

    <template v-slot:content>
      <div
        v-if="assetIds"
        class="overwrite-live-modal-container"
      >
        <div>
          <h4>Are you sure you want to overwrite live {{ assetIds.length }} {{ assetType }}<span v-if="assetIds.length > 1">s</span>?</h4>
        </div>
      </div>
    </template>
  </bulk-action-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import railsRoutes from '@/store/rails-routes.js'
import BulkActionModal from '@/components/assets/shared/bulk_actions/BulkActionModal.vue'
import BulkActionsMixin from '@/mixins/bulk-actions.js'

export default {
  components: {
    BulkActionModal
  },
  mixins: [BulkActionsMixin],
  data () {
    return {
      saving: false
    }
  },
  computed: {
    ...mapGetters([
      'selectAll',
      'searchResults',
      'asset',
      'assetType'
    ])
  },
  methods: {
    overwriteLive () {
      if (!this.saving) {
        this.saving = true
        var params = {
          asset_ids: this.assetIds
        }

        axios.post(railsRoutes.api_path({ type: this.assetType + 's', action: 'batch_overwrite_live' }), params).then(response => {
          this.saving = false
          this.$modal.hide('bulk-action-modal')
          let assetName = this.assetType.charAt(0).toUpperCase() + this.assetType.slice(1)
          this.$notify({ title: `Live ${assetName}s Overwritten`, type: 'success' })
        })
      }
    }
  },
  mounted () {
    this.updateAssetIds()
  }
}

</script>
<style lang="scss" scoped>
.overwrite-live-modal-container {
  background-color: #FCFBFC;
  padding: 20px;

  .button {
    color: white;
  }

  > label {
    font-weight: 700;
    margin-bottom: 8px;
    font-size: 18px;
  }
  h4 {
    text-align: center;
  }
}
</style>
