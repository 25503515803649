<template>
  <div class="inner-content">
    <div class="flex-row search-row-section">
      <div class="flex-row-item flex-search-input">
        <search-input />
      </div>
    </div>
    <div class="main-table-container">
      <search-results />
    </div>
    <delete-confirmation>
      <template slot="name">
        {{ asset.title }}
      </template>
      <template slot="body">
        Are you sure?
      </template>
    </delete-confirmation>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import SearchInput from '../../assets/shared/SearchInput'
import SearchResults from '../../shared/SearchResults.vue'
import searchKeys from '../../../mixins/search-keys'
import DeleteConfirmation from '../../assets/shared/DeleteConfirmation'

export default {
  name: 'CatalogPageSearch',
  components: {
    SearchInput,
    SearchResults,
    DeleteConfirmation
  },
  mixins: [searchKeys],
  computed: {
    ...mapGetters([
      'asset'
    ])
  },
  async mounted () {
    this.setAssetType({ assetType: 'catalog_page' })

    const keys = [
      { key: 'ids', display_name: 'IDs', type: 'text' },
      { key: 'products', display_name: 'Product', type: 'product', hideCategories: true },
      { key: 'created_at', display_name: 'Created At', type: 'date_range' },
      { key: 'updated_at', display_name: 'Updated At', type: 'date_range' }
    ]
    this.setAvailableKeys(keys)
  },
  methods: {
    ...mapMutations([
      'setAssetType'
    ])
  }
}
</script>
<style lang="scss">
.table-container {
  border: solid 1px $grey-3;
}
</style>
