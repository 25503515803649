<template>
  <router-link
    :to="{ name: routeName, params: { id: result.id  }}"
    class="hlt-button hlt-edit-button secondary hlt-small-button"
    @click.native="buttonClicked"
  >
    <div v-if="!isLoading">
      {{ buttonTitle }}
    </div>
    <div
      v-else
      class="loading"
    >
      <small>Loading...</small>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    routeName: {
      type: String
    },
    result: {
      type: Object,
      required: true
    },
    buttonTitle: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      isLoading: false
    }
  },
  methods: {
    buttonClicked () {
      this.isLoading = true
    }
  }
}
</script>
<style lang="scss" scoped>
  .hlt-edit-button {
    margin: 10px 0;
  }
</style>
