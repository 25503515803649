<template>
  <div class="flex-table-row deck-result-view">
    <div class="flex-table-cell">
      <div class="result-data result-checkbox">
        <input
          :id="&quot;checkbox&quot; + result.id"
          v-model="result.selected"
          class="asset_id"
          name="tag_id"
          type="checkbox"
          :value="result.id"
        >
        <label
          class="checkbox-label"
          :for="&quot;checkbox&quot; + result.id"
        />
      </div>
    </div>
    <div class="flex-table-cell result-id">
      <div class="result-data-id result-data">
        <div>Draft: {{ result.id }}</div>
        <div>Live: {{ result.live_deck_id || "N/A" }}</div>
      </div>
    </div>
    <div class="flex-table-cell result-title">
      <div class="result-data">
        <div
          class="tag-name"
          v-html="highlight_search_text(result.title, 'title')"
        />
      </div>
    </div>
    <div class="flex-table-cell result-apps">
      <div class="result-data apps">
        <div
          v-for="(app, i) in result.apps"
          :key="i"
          class="flex-product"
        >
          <div class="table-data-size app-name">
            {{ app.internal_name | truncate(40) }}
          </div>
        </div>
      </div>
    </div>
    <div class="flex-table-cell">
      <div class="result-data action-button-cell">
        <div class="button-container">
          <edit-button
            route-name="deck"
            :result="result"
            button-title="Edit"
          />
          <button
            class="hlt-button warning hlt-small-button delete-deck"
            @click="deleteRecord(result)"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import highlighter from '../../../mixins/highlighter.js'
import EditButton from '../../assets/shared/page_components/EditButton'
import railsRoutes from '../../../store/rails-routes'

export default {
  components: {
    EditButton
  },
  mixins: [
    highlighter
  ],
  props: ['result'],
  computed: {
    ...mapGetters([
      'asset',
      'deleteAsset'
    ])
  },
  watch: {
    deleteAsset (val, oldVal) {
      if ((val === true) && (this.asset.id == this.result.id)) {
        this.delete({ record: this.asset, url: railsRoutes.api_path({ type: 'decks' }) }).then(response => {
          this.$modal.hide('delete-modal')
          this.setDeleteAsset({ value: false })
          this.resetSearchResults()
          this.searching()
        })
      }
    }
  },
  methods: {
    deleteRecord (record) {
      this.setAsset({ asset: record })
      if (this.$modal) {
        this.$modal.show('delete-modal')
      }
    },
    ...mapMutations([
      'setAsset',
      'resetSearchResults',
      'setDeleteAsset'
    ]),
    ...mapActions([
      'delete',
      'searching'
    ])
  }
}
</script>
<style lang="scss">
.deck-result-view {
  .asset-action-button {
    border: 2px solid #c2c8cc;
    width: 85px !important;
    max-width: none;
    margin: 0.325rem 0;
  }
  .tag-name {
    display: inline;
  }
}
</style>
