<template>
  <div class="flex-table-row modules-result-view">
    <div class="center select-all flex-table-cell">
      <div class="result-data result-checkbox">
        <input
          :id="&quot;checkbox&quot; + result.id"
          v-model="result.selected"
          class="asset_id"
          name="module_id"
          type="checkbox"
          :value="result.id"
        >
        <label
          class="checkbox-label"
          :for="&quot;checkbox&quot; + result.id"
        />
      </div>
    </div>
    <div class="flex-table-cell result-id">
      <div class="result-data-id result-data">
        <div>Draft: {{ result.id }}</div>
        <div>Live: {{ result.live_learning_module_id || "N/A" }}</div>
      </div>
    </div>
    <div class="flex-table-cell result-title">
      <div class="result-data table-data-size">
        <div
          class="module-name"
          v-html="highlight_search_text(result.title, 'name')"
        />
      </div>
    </div>
    <div class="flex-table-cell result-apps">
      <div class="result-data apps">
        <div
          v-for="(app, i) in result.apps"
          :key="i"
          class="flex-product"
        >
          <div class="table-data-size app-name">
            {{ app.internal_name | truncate(40) }}
          </div>
        </div>
      </div>
    </div>

    <div class="flex-table-cell actions">
      <div class="result-data table-data-size">
        <div class="button-container">
          <edit-button
            route-name="learning_module"
            :result="result"
            button-title="Edit"
          />
          <button
            class="hlt-button warning hlt-small-button delete-module"
            @click="deleteRecord(result)"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import railsRoutes from '../../../store/rails-routes'
import { mixin as clickaway } from 'vue-clickaway2'
import EditButton from '../shared/page_components/EditButton'
import highlighter from '../../../mixins/highlighter.js'

export default {
  components: {
    EditButton
  },
  mixins: [
    clickaway,
    highlighter
  ],
  props: ['result'],
  computed: {
    ...mapGetters([
      'asset',
      'deleteAsset'
    ])
  },
  watch: {
    deleteAsset (val, oldVal) {
      if ((val === true) && (this.asset.id == this.result.id)) {
        this.delete({ record: this.asset, url: railsRoutes.api_path({ type: 'learning_modules' }) }).then(response => {
          this.$modal.hide('delete-modal')
          this.setDeleteAsset({ value: false })
          this.resetSearchResults()
          this.searching()
        })
      }
    }
  },
  methods: {
    deleteRecord (record) {
      this.setAsset({ asset: record })
      if (this.$modal) {
        this.$modal.show('delete-modal')
      }
    },
    ...mapMutations([
      'setAsset',
      'resetSearchResults',
      'setDeleteAsset'
    ]),
    ...mapActions([
      'delete',
      'searching'
    ])
  }
}
</script>
<style lang="scss">
.module-result-view {
  .asset-action-button {
    width: 85px !important;
    max-width: none;
    margin: 0.325rem 0;
  }
}
.prod-assoc-table {
  .flex-table-row:last-child {
    border-bottom: 0;
  }
}

.threes {
  padding: 0px !important;
  text-align: left;

  img {
    fill: $grey-3;
  }
  div {
    display: inline-block;
    text-align: center;
    height: 25px;
  }
  .copy {
    border-right: 2px solid $grey-3;
    padding-top: 3px;
    padding-left: 5px;
    padding-right: 5px;
    cursor: pointer;

    img {
      width: 15px;
    }
  }
  .meta {
    border-right: 2px solid $grey-3;
    padding-top: 3px;
    padding-left: 1px;
    padding-right: 5px;
    cursor: pointer;

    img {
      width: 15px;
    }
  }
  .trash {
    img {
      margin-top: -2px;
      width: 15px;
    }
    padding-top: 0px;
    padding-left: 1px;
    padding-right: 5px;
  }
}
.asset-action-button {
  border: 2px solid $grey-3 !important;
  width: 85px !important;
  max-width: none;
  margin: 0.325rem 0;
}
.module-name {
  display: inline;
}
</style>
