<template>
  <div class="inner-content">
    <div class="flex-row search-row-section">
      <div class="flex-row-item flex-search-input">
        <search-input />
      </div>
    </div>
    <div class="main-table-container">
      <search-results />
    </div>
    <delete-confirmation>
      <template slot="name">
        {{ asset.name }}
      </template>
      <template slot="body">
        {{ taggingsCount }} apps use this tag.
      </template>
    </delete-confirmation>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import SearchInput from '../../assets/shared/SearchInput.vue'
import SearchResults from '../../shared/SearchResults.vue'
import DeleteConfirmation from '../../assets/shared/DeleteConfirmation'
import searchKeys from '../../../mixins/search-keys'

export default {
  name: 'TagSearch',
  components: { SearchInput, SearchResults, DeleteConfirmation },
  mixins: [searchKeys],
  data () {
    return {
      types: [
        'Standard',
        'Content',
        'Asset'
      ]
    }
  },
  computed: {
    taggingsCount () {
      if (this.asset.id) {
        return this.asset.taggings_count
      } else {
        return 'Unknown'
      }
    },
    ...mapGetters([
      'asset',
      'verticals'
    ])
  },
  async created () {
    this.setAssetType({ assetType: 'tag' })
    this.setSearchOrder({ searchOrder: [['name'], ['asc']] })

    await this.getVerticals()
    const keys = [
      { key: 'ids', display_name: 'IDs', type: 'text' },
      { key: 'name', display_name: 'Name', type: 'text' },
      { key: 'standard_set', display_name: 'Standard Set', type: 'text' },
      { key: 'tag_types', display_name: 'Tag Type', type: 'multiple_autocomplete', options: this.types.sort().map(x => { return { name: x } }), trackBy: 'name', label: 'name' },
      { key: 'vertical_ids', display_name: 'Vertical', type: 'multiple_autocomplete', options: this.verticals, trackBy: 'id', label: 'name' },
      { key: 'created_at', display_name: 'Created At', type: 'date_range' },
      { key: 'updated_at', display_name: 'Updated At', type: 'date_range' }
    ]
    this.setAvailableKeys(keys)
  },
  methods: {
    ...mapMutations([
      'setAssetType',
      'setSearchOrder'
    ]),
    ...mapActions([
      'getVerticals'
    ])
  }
}
</script>
<style lang="scss">
.table-container {
  border: solid 1px $grey-3;
}
</style>
