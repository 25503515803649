<template>
  <bulk-action-modal @confirmed="upload()" @closed="$emit('closed')">
    <template v-slot:title>Batch Upload</template>

    <template v-slot:content>
      <div class="batch-upload-modal-container">
        <div class="file">
          <label>Select Zip File</label>
          <input
            ref="file"
            class="zipfile"
            type="file"
            name="file"
            @change="handleFileUpload"
          >
        </div>
        <product-association :asset="fakeAsset" />
        <div class="source-selection">
          <label for="">Content Source</label>
          <select
            v-model="contentSourceId"
            class="hlt-select"
          >
            <option value="null">
              Select Content Source
            </option>
            <option
              v-for="(option, i) in contentSources"
              :key="i"
              :value="option.id"
            >
              {{ option.name }}
            </option>
          </select>
        </div>
      </div>
    </template>
  </bulk-action-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import railsRoutes from '@/store/rails-routes.js'
import ProductAssociation from '../shared/ProductAssociation'
import BulkActionModal from '@/components/assets/shared/bulk_actions/BulkActionModal.vue'
import BulkActionsMixin from '@/mixins/bulk-actions.js'

export default {
  components: {
    ProductAssociation,
    BulkActionModal
  },
  mixins: [BulkActionsMixin],
  data () {
    return {
      saving: false,
      zipfile: null,
      fakeAsset: { product_associations: [] },
      contentSources: [],
      contentSourceId: null
    }
  },
  computed: {
    ...mapGetters([
      'selectAll',
      'searchResults',
      'assetType',
      'currentUser'
    ])
  },
  created () {
    if (this.currentUser && this.currentUser.permissions.can_read_content_sources) {
      axios.get(railsRoutes.api_path({ type: 'content_sources' })).then(response => {
        this.contentSources = this._.sortBy(response.data.records, ['name'])
      })
    }
  },
  methods: {
    reset () {
      this.fakeAsset = { product_associations: [] }
      this.zipfile = null
      this.saving = false
    },
    handleFileUpload () {
      this.zipfile = this.$refs.file.files[0]
    },
    upload () {
      if (!this.saving && this.zipfile) {
        this.saving = true
        this.getSignedUrl()
      }
    },
    getSignedUrl () {
      const params = {
        filename: this.zipfile.name,
        type: this.zipfile.type,
        content_source_id: this.contentSourceId
      }

      axios.post(railsRoutes.api_path({ type: 'attachments', action: 'batch_upload_url' }), params).then(response => {
        const key = response.data.key
        const url = response.data.url
        this.uploadToS3(url, key)
      }).catch(error => {
        console.log('Error fetching presigned url', error)
        this.$notify({ title: 'Error Uploading. Try Again.', type: 'error' })
      })
    },
    uploadToS3 (url, key) {
      const headers = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      axios.put(url, this.zipfile, headers).then(response => {
        this.processBatchUpload(key)
      }).catch(error => {
        console.log('Error uploading to S3', error)
        this.$notify({ title: 'Error Uploading. Try Again.', type: 'error' })
      })
    },
    processBatchUpload (key) {
      const params = {
        key: key,
        product_associations: this.fakeAsset.product_associations,
        content_source_id: this.contentSourceId
      }

      axios.post(railsRoutes.api_path({ type: 'attachments', action: 'process_batch_upload' }), params).then(response => {
        this.reset()
        this.$modal.hide('bulk-action-modal')
        this.$notify({ title: 'Attachment Batch Processing...', type: 'success' })
      }).catch(error => {
        console.log('Error starting batch attachment background process', error)
        this.$notify({ title: 'Error Uploading. Try Again.', type: 'error' })
      })
    }
  }
}

</script>
<style lang="scss">
.batch-upload-modal-container {
  background-color: #FCFBFC;
  padding: 20px;

  .button {
    color: white;
  }

  > label {
    font-weight: 700;
    margin-bottom: 8px;
    font-size: 18px;
  }
  .source-selection {
    margin-bottom: 15px;
    select {
      width: 75%;
    }
  }
  .state-selection {
    width: 200px;
  }
}
</style>
