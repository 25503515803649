<template>
  <div class="inner-content">
    <div class="flex-row search-row-section">
      <div class="flex-row-item flex-bulk-actions">
        <asset-bulk-actions />
      </div>
      <div class="flex-row-item flex-search-input">
        <search-input />
      </div>
    </div>
    <div class="main-table-container">
      <search-results />
    </div>
    <delete-confirmation>
      <template slot="name">
        {{ asset.title }}
      </template>
      <template slot="body">
        {{ appsCount }} apps use this topic.
        {{ asset.descendants_count }} topics are descendants from this topic.
      </template>
    </delete-confirmation>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import AssetBulkActions from '../shared/BulkActions.vue'
import SearchInput from '../shared/SearchInput.vue'
import SearchResults from '../../shared/SearchResults.vue'
import DeleteConfirmation from '../shared/DeleteConfirmation.vue'
import searchKeys from '../../../mixins/search-keys.js'

export default {
  components: { AssetBulkActions, SearchInput, SearchResults, DeleteConfirmation },
  mixins: [searchKeys],
  data () {
    return {
      states: [
        'New',
        'Review',
        'Edit',
        'Preflight',
        'Approved'
      ],
      topicTypes: [
        {
          name: 'Asset Tray'
        },
        {
          name: 'Case Study'
        },
        {
          name: 'Reference'
        },
        {
          name: 'Standard'
        }
      ]
    }
  },
  computed: {
    appsCount () {
      if (this.asset.id) {
        return this.asset.root_apps.length
      } else {
        return 'unknown'
      }
    },

    ...mapGetters([
      'asset',
      'tags',
      'contentSources'
    ])
  },
  async created () {
    this.setAssetType({ assetType: 'topic' })
    this.setSearchOrder({ searchOrder: [['path'], ['asc']] })
    await this.getTags()
    await this.getContentSources()
    if (this.tags) {
      var keys = [
        { key: 'ids', display_name: 'IDs', type: 'text' },
        { key: 'products', display_name: 'Product', type: 'product' },
        { key: 'category_type_ids', display_name: 'Category Type', type: 'category_type' },
        { key: 'topic_types', display_name: 'Topic Type', type: 'multiple_autocomplete', options: this.topicTypes, trackBy: 'name', label: 'name' },

        { key: 'display_title', display_name: 'Display Title', type: 'text' },
        { key: 'display_description', display_name: 'Display Description', type: 'text' },
        { key: 'display_attachment_id', display_name: 'Display Attachment ID', type: 'text' },
        { key: 'difficulty_level', display_name: 'Difficulty Level', type: 'text' },

        { key: 'title', display_name: 'Title', type: 'text' },
        { key: 'content', display_name: 'Content', type: 'text' },
        { key: 'state', display_name: 'State', type: 'multiple_autocomplete', options: this.states.sort().map(x => { return { name: x } }), trackBy: 'name', label: 'name' },
        { key: 'created_at', display_name: 'Created At', type: 'date_range' },
        { key: 'updated_at', display_name: 'Updated At', type: 'date_range' },
        { key: 'revised_at', display_name: 'Revised At', type: 'date_range' },
        { key: 'tags', display_name: 'Tags', type: 'multiple_autocomplete', options: this.tags, trackBy: 'name', label: 'name' },
        { key: 'content_source_ids', display_name: 'Content Source', type: 'multiple_autocomplete', options: this.contentSources, trackBy: 'id', label: 'name' }
      ]
      this.setAvailableKeys(keys)
    }
  },
  methods: {
    ...mapMutations([
      'setAssetType',
      'setSearchAssetType',
      'setSearchOrder'
    ]),
    ...mapActions([
      'getTags',
      'getContentSources'
    ])
  }

}
</script>
