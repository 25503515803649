<template>
  <div class="inner-content">
    <div class="flex-row search-row-section">
      <div class="flex-row-item flex-bulk-actions">
        <asset-bulk-actions />
      </div>
      <div class="flex-row-item flex-search-input">
        <search-input />
      </div>
    </div>
    <div class="main-table-container">
      <search-results />
    </div>
    <delete-confirmation>
      <template slot="name">
        {{ asset.title }}
      </template>
      <template slot="body">
        {{ deckCount }} apps use this deck.
      </template>
    </delete-confirmation>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import SearchInput from '../../assets/shared/SearchInput'
import SearchResults from '../../shared/SearchResults.vue'
import DeleteConfirmation from '../../assets/shared/DeleteConfirmation'
import searchKeys from '@/mixins/search-keys'
import AssetBulkActions from '../../assets/shared/BulkActions.vue'

export default {
  name: 'DeckSearch',
  components: {
    SearchInput,
    SearchResults,
    DeleteConfirmation,
    AssetBulkActions
  },
  mixins: [searchKeys],
  computed: {
    deckCount () {
      if (this.asset.id) {
        return this.asset.apps.length
      } else {
        return 'unknown'
      }
    },
    ...mapGetters([
      'asset',
      'contentSources',
      'tags'
    ])
  },
  async created () {
    this.setAssetType({ assetType: 'deck' })
    await this.getContentSources()
    await this.getTags()

    const keys = [
      { key: 'ids', display_name: 'IDs', type: 'text' },
      { key: 'display_title', display_name: 'Display Title', type: 'text' },
      { key: 'display_description', display_name: 'Display Description', type: 'text' },
      { key: 'display_attachment_id', display_name: 'Display Attachment ID', type: 'text' },
      { key: 'difficulty_level', display_name: 'Difficulty Level', type: 'text' },
      { key: 'title', display_name: 'Title', type: 'text' },
      { key: 'products', display_name: 'Product', type: 'product' },
      { key: 'content_source_ids', display_name: 'Content Source', type: 'multiple_autocomplete', options: this.contentSources, trackBy: 'id', label: 'name' },
      { key: 'tags', display_name: 'Tags', type: 'multiple_autocomplete', options: this.tags, trackBy: 'name', label: 'name' },
      { key: 'created_at', display_name: 'Created At', type: 'date_range' },
      { key: 'updated_at', display_name: 'Updated At', type: 'date_range' },
      { key: 'revised_at', display_name: 'Revised At', type: 'date_range' }
    ]
    this.setAvailableKeys(keys)
  },
  methods: {
    ...mapMutations([
      'setAssetType'
    ]),
    ...mapActions([
      'getContentSources',
      'getTags'
    ])
  }
}
</script>
<style lang="scss">
.table-container {
  border: solid 1px $grey-3;
}
</style>
