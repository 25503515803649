<template>
  <bulk-action-modal @confirmed="deleteAssets()" @closed="$emit('closed')">
    <template v-slot:title>Delete Item</template>

    <template v-slot:appliesTo>
      <span>Applies to {{ assetIds.length }} {{ assetType }}<span v-if="assetIds.length > 1">s</span></span>
    </template>

    <template v-slot:content>
      <h4>
        Are you sure you want to delete {{ assetIds.length }} {{ assetType }}<span v-if="assetIds.length > 1">s</span>?
      </h4>
    </template>
  </bulk-action-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import railsRoutes from '@/store/rails-routes.js'
import BulkActionModal from '@/components/assets/shared/bulk_actions/BulkActionModal.vue'
import BulkActionsMixin from '@/mixins/bulk-actions.js'

export default {
  components: {
    BulkActionModal
  },
  mixins: [BulkActionsMixin],
  data () {
    return {
      saving: false
    }
  },
  computed: {
    ...mapGetters([
      'selectAll',
      'searchResults',
      'asset',
      'assetType'
    ])
  },
  methods: {
    deleteAssets () {
      if (!this.saving) {
        this.saving = true
        var params = {
          asset_ids: this.assetIds
        }

        axios.post(railsRoutes.api_path({ type: this.assetType + 's', action: 'batch_delete' }), params).then(response => {
          this.saving = false
          let assetName = this.assetType.charAt(0).toUpperCase() + this.assetType.slice(1)
          this.$notify({ title: `${assetName} deleted`, type: 'success' })
        })
      }
    }
  },
  mounted () {
    this.updateAssetIds()
  }
}

</script>
<style lang="scss" scoped>
h4 {
  text-align: center;
  margin: 40px;
}
</style>
