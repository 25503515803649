<template>
  <bulk-action-modal @confirmed="save()" @closed="$emit('closed')">
    <template v-slot:title>Assign Assets</template>

    <template v-slot:appliesTo>
      <span>Applies to {{ assetIds.length }} {{ assetType }}<span v-if="assetIds.length > 1">s</span></span>
    </template>

    <template v-slot:content>
      <div class="assign-assets-container">
        <label>Assign to</label>

        <hlt-multiselect
          ref="multiselect"
          v-model="assignTo"
          track-by="id"
          label="full_name"
          class="assign-input"
          :options="cmsUsers"
          :multiple="false"
          :searchable="true"
          :max-height="150"
          :show-no-results="true"
          :allow-empty="true"
          :clear-on-select="false"
          :show-labels="false"
          placeholder="Select Assignee"
        >
        </hlt-multiselect>

        <label>Reviewer <span class="optional">(Optional)</span></label>

        <hlt-multiselect
          ref="multiselect2"
          v-model="reviewer"
          track-by="id"
          label="full_name"
          class="reviewer-input"
          :options="cmsUsers"
          :multiple="false"
          :searchable="true"
          :max-height="150"
          :show-no-results="true"
          :allow-empty="true"
          :clear-on-select="false"
          :show-labels="false"
          placeholder="Select Reviewer"
        >
        </hlt-multiselect>
      </div>
    </template>
  </bulk-action-modal>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import railsRoutes from '../../../store/rails-routes'
import axios from 'axios'
import HLTMultiselect from '../../shared/VueMultiSelect'
import BulkActionModal from '@/components/assets/shared/bulk_actions/BulkActionModal.vue'
import BulkActionsMixin from '@/mixins/bulk-actions.js'

export default {
  components: {
    'hlt-multiselect': HLTMultiselect,
    BulkActionModal
  },
  mixins: [BulkActionsMixin],
  data () {
    return {
      saving: false,
      assignTo: null,
      reviewer: null
    }
  },
  computed: {
    ...mapGetters([
      'asset',
      'assetType',
      'currentUser',
      'cmsUsers'
    ]),
    apiAssetType () {
      if (this.assetType == 'item') {
        return 'V3Flashcard'
      } else {
        return this.assetType.replace(/^\w/, c => c.toUpperCase()) // Capitalize first letter
      }
    }
  },
  created () {
    if (this.currentUser.permissions.can_write_asset_assignments) {
      axios.get(railsRoutes.api_path({ type: 'admin_users' })).then(response => {
        this.setCmsUsers({ cmsUsers: response.data.records })
      })
    }
  },
  methods: {
    save () {
      if (!this.saving) {
        // Disable save button so it can't be pressed multiple times
        this.saving = true
        const url = railsRoutes.api_path({ type: 'asset_assignments', action: 'batch_create' })

        var params = {
          asset_ids: this.assetIds,
          asset_type: this.apiAssetType,
          assigned_to_admin_user_id: this.assignTo.id,
          reviewer_admin_user_id: this.reviewer?.id
        }

        axios.post(url, params).then(response => {
          this.saving = false
          this.$modal.hide('bulk-action-modal')
          this.$notify({ title: 'Asset Assignments Created', type: 'success' })
        }).catch(error => {
          console.error(error)
          this.$notify({ title: 'Error Saving Asset Assignments', type: 'error' })
        })
      }
    },
    ...mapMutations([
      'setCmsUsers'
    ])
  },
  mounted () {
    this.updateAssetIds()
  }
}

</script>

<style lang="scss" scoped>
.assign-assets-container {
  height: 400px;
  min-height: 400px;
  width: 400px;
  padding: 20px;
  .optional {
    color: $space-gray;
    font-size: .8em;
    letter-spacing: 0.5px;
  }
}
</style>
