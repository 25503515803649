<template>
  <modal
    name="bulk-action-modal"
    height="auto"
    :scrollable="false"
    class="vue-modal bulk-action-modal"
    width="70%"
    @before-close='$emit("closed")'
  >
    <div class="vue-modal-inner">
      <div class="vue-modal-topbar">
        <h4>
          <slot name="title" />
        </h4>
        <slot name="appliesTo" />
      </div>
      <div class="modal-container">
        <slot name="content" />
      </div>

      <div class="vue-modal-bottombar modal-buttons">
        <button
          class="button cancel-button modal-button"
          @click="cancel()"
        >
          Cancel
        </button>
        <button
          class="button solid-green-button modal-button"
          @click="confirm()"
        >
          Confirm
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  methods: {
    confirm () {
      this.$emit('confirmed')
      this.cancel()
    },
    cancel () {
      this.$modal.hide('bulk-action-modal')
    },

  }
}
</script>

<style lang="scss">
.bulk-action-modal {
  .vue-modal-topbar {
    display: flex;
    justify-content: space-between;
  }
  h4 {
    color: $slate-gray;
  }
  button {
    padding: 6px 24px !important;
  }
  .cancel-button {
    background: $space-gray;
    border: 1px solid $slate-gray;
  }
}
</style>