<template>
  <div class="flex-table-row assignment-result-view">
    <div class="flex-table-cell">
      <div class="result-data result-checkbox">
        <input
          :id="'checkbox' + result.id"
          v-model="result.selected"
          class="asset_id"
          type="checkbox"
          :value="result.id"
        >
        <label
          class="checkbox-label"
          :for="'checkbox' + result.id"
        />
      </div>
    </div>
    <div class="flex-table-cell result-id">
      <div class="result-data-id result-data">
        {{ result.asset_id }}
      </div>
    </div>
    <div class="flex-table-cell result-id">
      <div class="result-asset-type result-data">
        {{ assetType(result.asset_type) }}
      </div>
    </div>
    <div class="flex-table-cell result-id" v-if="isManager">
      <div class="result-assigned-to result-data">
        {{ result.assigned_to_admin_user.full_name }}
      </div>
    </div>
    <div class="flex-table-cell" v-if="isManager">
      <div class="result-reviewer result-data">
        {{ reviewerName }}
      </div>
    </div>
    <div class="flex-table-cell result-apps">
      <div class="result-data flex-product">
        <div class="table-data-size result-preview">
          {{ result.preview | truncate(80) }}
        </div>
      </div>
    </div>
    <div class="flex-table-cell actions">
      <div class="result-data">
        <router-link
          :to="{ name: 'asset_assignment', params: { id: result.id }}"
          class="hlt-button hlt-small-button hlt-edit-button secondary review-button"
          @click="setAssetId(result.asset_id)"
        >
          <span v-if="result.state === 'completed'">
            View
          </span>
          <span v-else>
            Review
          </span>
        </router-link>
        <span
          class="hlt-button hlt-small-button warning delete"
          v-if="showDeleteButton"
          @click="deleteRecord(result)"
        >
          Delete
        </span>

      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import highlighter from '../../../mixins/highlighter.js'
import railsRoutes from '../../../store/rails-routes'
import axios from 'axios'
export default {
  name: 'AssignmentSearchResults',
  mixins: [
    highlighter
  ],
  props: ['result'],
  computed: {
    ...mapGetters([
      'asset',
      'cmsUsers',
      'currentUser',
      'deleteAsset'
    ]),
    reviewerName () {
      return this.result.reviewer_admin_user?.full_name || 'N/A'
    },
    isManager () {
      return this.currentUser.permissions.is_a_asset_assigner
    },
    showDeleteButton () {
      return this.currentUser.is_admin || this.isManager
    }
  },
  watch: {
    deleteAsset (val, oldVal) {
      if ((val == true) && (this.asset.id == this.result.id)) {
        this.delete({ record: this.asset, url: railsRoutes.api_path({ type: 'asset_assignments' }) }).then(response => {
          this.$modal.hide('delete-modal')
          this.setDeleteAsset({ value: false })
          this.resetSearchResults()
          this.searching()
        })
      }
    }
  },
  created () {
    if (this.currentUser.permissions.can_write_asset_assignments) {
      axios.get(railsRoutes.api_path({ type: 'admin_users' })).then(response => {
        this.setCmsUsers({ cmsUsers: response.data.records })
      })
    }
  },
  methods: {
    ...mapMutations([
      'setCmsUsers',
      'setAsset',
      'setAssetType',
      'setDeleteAsset',
      'resetSearchResults'
    ]),
    ...mapActions([
      'delete',
      'searching'
    ]),
    assetType (assetType) {
      if (assetType === 'V3Flashcard') {
        return 'Item'
      }
      return assetType
    },
    deleteRecord (record) {
      this.setAsset({ asset: record })
      if (this.$modal) {
        this.$modal.show('delete-modal')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.actions .result-data {
  flex-direction: row;
  .review-button {
    padding: 5px;
  }
  .delete {
    margin-left: 20px;
    padding: 5px;
  }
}
</style>
