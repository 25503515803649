<template>
  <div :class="['flex-table-row', 'topics-result-view', { 'odd': oddRow }]">
    <div class="flex-table-cell">
      <div class="result-data result-checkbox">
        <input
          :id="&quot;checkbox&quot; + result.id"
          v-model="result.selected"
          class="asset_id"
          name="topic_id"
          type="checkbox"
          :value="result.id"
        >
        <label
          class="checkbox-label"
          :for="&quot;checkbox&quot; + result.id"
        />
      </div>
    </div>
    <div class="flex-table-cell result-id">
      <div class="result-data result-data-id">
        <div class="ids">
          <div>Draft: {{ result.draft_topic_id }}</div>
          <div>Live: {{ result.live_topic_id || "N/A" }}</div>
        </div>
        <div class="root-ids">
          <div>Draft Root: {{ result.draft_root_id }}</div>
          <div>Live Root: {{ result.live_root_id || "N/A" }}</div>
        </div>
      </div>
    </div>
    <div class="flex-table-cell content-cell">
      <div class="result-data">
        <div>
          <span
            v-if="result.root_title"
            class="result-root-title"
            v-html="highlight_search_text(result.root_title, 'title')"
          />
        </div>
        <div v-if="result.root_id != result.id ">
          <span
            v-if="result.title"
            class="result-title"
            v-html="highlight_search_text(result.title, 'title')"
          />
        </div>
        <div
          v-if="result.content"
          class="result-content"
        >
          <div v-html="highlight_search_text(result.content, 'content')" class="content" />
          <div class="fade-text-feature" />
      </div>
      </div>
    </div>
    <div class="flex-table-cell result-apps">
      <div class="result-data">
        <div
          v-for="(app, i) in result.root_apps"
          :key="i"
          class="flex-product"
        >
          <div class="table-data-size app-name">
            {{ app.internal_name | truncate(40) }}
          </div>
        </div>
      </div>
      <div class="fade-text-feature" />
    </div>
    <div class="flex-table-cell">
      <div class="result-data action-button-cell">
        <div class="button-container">
          <edit-button
            :route-name="'topic'"
            :result="result"
            button-title="Edit"
          />
          <button
            v-if="currentUser.permissions.can_delete_topics"
            class="hlt-button warning delete-button hlt-small-button"
            @click="deleteRecord(result)"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import railsRoutes from '../../../store/rails-routes'
import EditButton from '../shared/page_components/EditButton'
import highlighter from '../../../mixins/highlighter.js'

export default {
  components: {
    EditButton
  },
  mixins: [highlighter],
  props: {
    result: {
      type: Object,
      required: true
    },
    tableIndex: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapGetters([
      'asset',
      'deleteAsset',
      'currentUser'
    ]),
    oddRow () {
      return this.tableIndex % 2 == 0
    },
  },
  watch: {
    deleteAsset (val, oldVal) {
      if ((val === true) && (this.asset.id === this.result.id)) {
        this.delete({ record: this.asset, url: railsRoutes.api_path({ type: 'topics' }) }).then(response => {
          this.$notify({ title: 'Topic Deleted', type: 'success' })
          this.$modal.hide('delete-modal')
          this.setDeleteAsset({ value: false })
          this.resetSearchResults()
          this.searching()
        })
      }
    }
  },
  methods: {
    deleteRecord (record) {
      this.setAsset({ asset: record })
      if (this.$modal) {
        this.$modal.show('delete-modal')
      }
    },
    ...mapMutations(['setAsset', 'setDeleteAsset', 'resetSearchResults']),
    ...mapActions(['delete', 'searching', 'copyToClipboard'])
  }
}
</script>
<style lang="scss" scoped>
.topics-result-view {
  max-height: 200px;
  overflow: hidden;

  .root-ids {
    margin-top: 20px;
  }
  .asset-action-button {
    border: 2px solid #c2c8cc;
    width: 85px !important;
    max-width: none;
    margin: 0.325rem 0;
  }
  .content-cell {
    padding: 10px !important;
    max-height: 200px;
    overflow: hidden;
    .result-root-title {
      font-weight: 700;
    }
    .result-title {
      font-weight: 600;
    }
  }
  .result-content {
    position: relative;
    max-height: 200px;
    overflow: hidden;
    padding-top: 10px !important;

    .content {
      height: 180px;
      overflow: hidden;
    }
  }
  .result-apps {
    position: relative;
    vertical-align: top;
    display: flex;
    flex-direction: column;
    max-height: 200px;
    .flex-product {
      flex-direction: row;
      flex-wrap: wrap;
      padding-bottom: 5px;
    }
  }
  .fade-text-feature {
    position: absolute;
    height: 40%;
    width: 100%;
    bottom: 0;
    left: 0;
    background: -moz-linear-gradient(top,  rgba(247,247,247,0) 0%, rgba(247,247,247,1) 80%, rgba(247,247,247,1) 100%);
    background: -webkit-linear-gradient(top,  rgba(247,247,247,0) 0%,rgba(247,247,247,1) 80%,rgba(247,247,247,1) 100%);
    background: linear-gradient(to bottom,  rgba(247,247,247,0) 0%,rgba(247,247,247,1) 80%,rgba(247,247,247,1) 100%);
  }
  &.odd {
    .fade-text-feature {
      background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 80%, rgba(255,255,255,1) 100%);
      background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 80%,rgba(255,255,255,1) 100%);
      background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 80%,rgba(255,255,255,1) 100%);
    }
  }
}
</style>
