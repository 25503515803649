<template>
  <div class="flex-table-row admin-lms-user-result-view">
    <div class="flex-table-cell">
      <div class="result-data result-checkbox">
        <input
          :id="&quot;checkbox&quot; + result.id"
          v-model="result.selected"
          class="asset_id"
          name="tag_id"
          type="checkbox"
          :value="result.id"
        >
        <label
          class="checkbox-label"
          :for="&quot;checkbox&quot; + result.id"
        />
      </div>
    </div>
    <div class="flex-table-cell result-profile-photo">
      <div class="result-data-id result-data">
        <user-svg v-if="!result.profile_photo_url" />
        <img class="profile-photo-preview" v-if="result.profile_photo_url" :src="result.profile_photo_url" />
      </div>
    </div>
    <div class="flex-table-cell">
      <div class="result-data-id result-name">
        {{ result.first_name }} {{ result.last_name }}
      </div>
    </div>
    <div class="flex-table-cell">
      <div class="result-data-id result-email">
        {{ result.email }}
      </div>
    </div>
    <div class="flex-table-cell result-is-admin">
      <div class="result-data-id result-data">
        <star-svg v-if="result.is_admin" />
        <span v-else>NO</span>
      </div>
    </div>
    <div class="flex-table-cell">
      <div class="result-data action-button-cell">
        <div class="button-container">
          <edit-button
            route-name="admin-lms-user"
            :result="result"
            button-title="Edit"
          />
          <button
            class="hlt-button warning hlt-small-button delete-admin-lms-user"
            @click="deleteRecord(result)"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import EditButton from '../../assets/shared/page_components/EditButton'
import railsRoutes from '../../../store/rails-routes'
import StarSvg from '@/assets/images/icons/certificate-solid.svg'
import UserSvg from '@/assets/images/icons/user-solid.svg'

export default {
  components: {
    EditButton,
    StarSvg,
    UserSvg
  },
  props: ['result'],
  computed: {
    ...mapGetters([
      'asset',
      'deleteAsset'
    ])
  },
  watch: {
    deleteAsset (val, oldVal) {
      if ((val === true) && (this.asset.id == this.result.id)) {
        this.delete({ record: this.asset, url: railsRoutes.api_path({ type: 'admin_users' }) }).then(() => {
          this.$modal.hide('delete-modal')
          this.setDeleteAsset({ value: false })
          this.resetSearchResults()
          this.searching()
        })
      }
    }
  },
  methods: {
    ...mapMutations([
      'setAsset',
      'resetSearchResults',
      'setDeleteAsset'
    ]),
    ...mapActions([
      'delete',
      'searching'
    ]),
    deleteRecord (record) {
      this.setAsset({ asset: record })
      if (this.$modal) {
        this.$modal.show('delete-modal')
      }
    }

  }
}
</script>

<style lang="scss" scoped>
.result-name, .result-email {
  padding: 20px;
}
</style>

<style lang="scss">
.admin-lms-user-result-view {
  .result-profile-photo {
    max-width: 100px;
    display: flex;
    justify-content: center;
    svg {
      width: 30px;
      fill: $space-gray-20;
      color: $space-gray-20;
    }
  }
  .result-is-admin {
    svg {
      width: 30px;
      fill: $orange-1;
      color: $orange-1;
    }
  }
  .asset-action-button {
    border: 2px solid #c2c8cc;
    width: 85px !important;
    max-width: none;
    margin: 0.325rem 0;
  }
}
</style>
