<template>
  <div class="assignment-tab-section" v-if="searchKeys">
    <ul class="assignment-tabs">
      <li :class="[{ active: activeTab === 'assigned' }, 'assignment-tab']">
        <a @click="showIndexState('assigned')">
          Assigned
        </a>
      </li>
      <li :class="[{ active: activeTab === 'submitted' }, 'assignment-tab']">
        <a @click="showIndexState('submitted')">
          Submitted
        </a>
      </li>
      <li :class="[{ active: activeTab === 'rejected' }, 'assignment-tab']">
        <a @click="showIndexState('rejected')">
          Needs Revisions
        </a>
      </li>
      <li :class="[{ active: activeTab === 'completed' }, 'assignment-tab']">
        <a @click="showIndexState('completed')">
          Completed
        </a>
      </li>

    </ul>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters([
      'currentUser',
      'searchKeys'
    ]),
    activeTab () {
      return this.searchKeys.filter(x => x.key === 'state')[0].value[0]
    }
  },
  methods: {
    ...mapMutations([
      'resetSearchResults',
      'selectSearchKey',
      'updateSearchKeyValue'
    ]),
    ...mapActions([
      'searching'
    ]),
    showIndexState (state) {
      this.resetSearchResults()
      this.selectSearchKey({ key: 'state' })
      this.updateSearchKeyValue(
        {
          key: 'state',
          value: [state],
          objValue: null
        }
      )
      this.searching()
    }
  }
}
</script>

<style lang="scss">
.assignment-tab-section {
  .assignment-tabs {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    margin: 18px 0;
    .assignment-tab {
      border: 1px solid $space-gray-10;
      padding: 6px 18px;
      background: $white-1;
      color: $slate-gray;
      display: block;
      font-family: 'Open Sans', sans-serif;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 0.03em;
      &:first-child {
        border-radius: 4px 0 0 4px;
      }
      &:last-child {
        border-radius: 0 4px 4px 0;
      }
      &.active {
        background: $cold-gray;
        color: $white-1;
        border: 1px solid $cold-gray;
        a, span {
          color: $white-1;
        }
      }
      a {
        color: $slate-gray;
      }
      span {
        margin-left: 12px;
        color: $space-black-10;
      }
    }
  }
}
</style>
