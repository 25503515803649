<template>
  <div class="inner-content">
    <div class="flex-row search-row-section">
      <div class="flex-row-item flex-search-input">
        <search-input />
      </div>
    </div>
    <div class="main-table-container">
      <search-results />
    </div>
    <delete-confirmation>
      <template slot="name">
        Delete Message:
      </template>
      <template slot="body">
        "{{ asset.message }}"
      </template>
    </delete-confirmation>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import SearchInput from '../../assets/shared/SearchInput'
import SearchResults from '../../shared/SearchResults.vue'
import DeleteConfirmation from '../../assets/shared/DeleteConfirmation'
import searchKeys from '../../../mixins/search-keys'

export default {
  name: 'UserSearch',
  components: { SearchInput, SearchResults, DeleteConfirmation },
  mixins: [searchKeys],
  data () {
    return {
      assetTypes: [
        {
          name: 'Item',
          value: 'V3Flashcard'
        }
      ],
      statuses: [
        {
          name: 'New'
        },
        {
          name: 'Completed'
        },
        {
          name: 'Monitor'
        },
        {
          name: 'Needs Review'
        },
        {
          name: 'Shadow Deleted'
        },
      ]
    }
  },
  computed: {
    ...mapGetters([
      'asset'
    ])
  },
  created () {
    this.setSearchOrder({ searchOrder: [['created_at'], ['desc']] })
    this.setAssetType({ assetType: 'discussion' })
    var keys = [
      { key: 'ids', display_name: 'IDs', type: 'text' },
      { key: 'email', display_name: 'Email', type: 'text' },
      { key: 'message', display_name: 'Message', type: 'text' },
      { key: 'products', display_name: 'Product', type: 'product', hideCategories: true },
      { key: 'statuses', display_name: 'Status', type: 'multiple_autocomplete', options: this.statuses, trackBy: 'name', label: 'name' },
      { key: 'asset_types', display_name: 'Asset Type', type: 'multiple_autocomplete', options: this.assetTypes, trackBy: 'value', label: 'name' },
      { key: 'asset_ids', display_name: 'Asset IDs', type: 'text' },
      { key: 'show_hlt', display_name: 'Show HLT Responses', type: 'boolean' },
      { key: 'no_hlt_reply', display_name: 'No HLT Reply', type: 'boolean' },
      { key: 'created_at', display_name: 'Created At', type: 'date_range' }
    ]
    this.setAvailableKeys(keys)
  },
  methods: {
    ...mapMutations([
      'setAssetType',
      'processSearchResults',
      'setSearchOrder'
    ]),
    ...mapActions([
      'getContentSources'
    ])
  }
}
</script>
<style lang="scss">
.table-container {
  border: solid 1px $grey-3;
}
</style>
