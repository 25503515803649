import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters([
      'selectedSearchKeys'
    ])
  },
  methods: {
    highlight_search_text(text, key) {
      if (!text) {
        return
      }
      let contentSearch = this.selectedSearchKeys.find(k => k.key === key)
      if ((contentSearch != undefined) || (contentSearch != null)) {
        const textToReplace = contentSearch.value.replace(/\"/g, '')
        const regex = new RegExp(textToReplace, "gi")
        return text.replace(
          regex,
          `<span class="highlighted-search-text">${textToReplace}</span>`
        )
      } else {
        return text
      }
    }
  }
}
