<template>
  <div class="inner-content">
    <div class="flex-row search-row-section">
      <div class="flex-row-item flex-search-input">
        <search-input />
      </div>
    </div>
    <div class="main-table-container">
      <search-results />
    </div>
    <delete-confirmation>
      <template slot="name">
        {{ asset.name }}
      </template>
    </delete-confirmation>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import SearchInput from '../../assets/shared/SearchInput.vue'
import SearchResults from '../../shared/SearchResults.vue'
import DeleteConfirmation from '../../assets/shared/DeleteConfirmation'
import searchKeys from '../../../mixins/search-keys'

export default {
  name: 'TagSearch',
  components: { SearchInput, SearchResults, DeleteConfirmation },
  mixins: [searchKeys],
  data () {
    return {
      types: [
        'Standard',
        'Content',
        'Asset'
      ]
    }
  },
  computed: {
    ...mapGetters([
      'asset'
    ])
  },
  async created () {
    this.setAssetType({ assetType: 'content_source' })
    this.setSearchOrder({ searchOrder: [['name'], ['asc']] })

    const keys = [
      { key: 'ids', display_name: 'IDs', type: 'text' },
      { key: 'name', display_name: 'Name', type: 'text' },
      { key: 'created_at', display_name: 'Created At', type: 'date_range' },
      { key: 'updated_at', display_name: 'Updated At', type: 'date_range' }
    ]
    this.setAvailableKeys(keys)
  },
  methods: {
    ...mapMutations([
      'setAssetType',
      'setSearchOrder'
    ]),
  }
}
</script>
<style lang="scss">
.table-container {
  border: solid 1px $grey-3;
}
</style>
