<template>
  <div class="inner-content assignments-index">
    <div class="flex-row search-row-section">
      <div class="flex-row-item flex-bulk-actions">
        <asset-bulk-actions />
      </div>
      <div class="flex-row-item flex-search-input">
        <search-input />
      </div>
    </div>
    <assignment-tabs />
    <div class="main-table-container">
      <search-results />
    </div>
    <delete-confirmation>
      <template slot="body">
        Asset ID: {{ asset.asset_id }}
      </template>
    </delete-confirmation>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import axios from 'axios'
import AssetBulkActions from '../../assets/shared/BulkActions'
import SearchInput from '../../assets/shared/SearchInput'
import SearchResults from '../../shared/SearchResults'
import searchKeys from '../../../mixins/search-keys'
import AssignmentTabs from './AssignmentTabs'
import DeleteConfirmation from '../../assets/shared/DeleteConfirmation.vue'
import railsRoutes from '../../../store/rails-routes'

export default {
  name: 'AssignmentIndex',
  components: {
    AssetBulkActions,
    SearchInput,
    SearchResults,
    AssignmentTabs,
    DeleteConfirmation
  },
  mixins: [searchKeys],
  data () {
    return {
      states: [
        'assigned',
        'submitted',
        'rejected',
        'completed'
      ]
    }
  },
  computed: {
    ...mapGetters([
      'asset',
      'currentUser'
    ])
  },
  created () {
    this.setAssetType({ assetType: 'asset_assignment' })
    const keys = [
      { key: 'ids', display_name: 'IDs', type: 'text' },
      { key: 'asset_ids', display_name: 'Asset IDs', type: 'text' },
      { key: 'state', display_name: 'State', type: 'multiple_autocomplete', options: this.states.sort().map(x => { return { name: x } }), trackBy: 'name', label: 'name', visible: false },
      { key: 'created_at', display_name: 'Created At', type: 'date_range' },
      { key: 'updated_at', display_name: 'Updated At', type: 'date_range' }
    ]

    if (this.currentUser.permissions.is_a_asset_assigner) {
      axios
        .get(railsRoutes.api_path({ type: 'admin_users' }))
        .then((response) => {
          this.setCmsUsers({ cmsUsers: response.data.records })
          keys.push({
            key: 'assigned_to_admin_user_id',
            display_name: 'Assigned To',
            type: 'multiple_autocomplete',
            options: this.sortedAdminUsers,
            trackBy: 'id',
            label: 'name'
          })
          keys.push({
            key: 'reviewer_admin_user_id',
            display_name: 'Reviewer',
            type: 'multiple_autocomplete',
            options: this.sortedAdminUsers,
            trackBy: 'id',
            label: 'name'
          })
          this.search(keys)
        })
    } else {
      this.search(keys)
    }
  },
  methods: {
    ...mapMutations([
      'setAssetType',
      'setSearchKeys',
      'resetSearchResults',
      'setSearchAssetType',
      'selectSearchKey',
      'updateSearchKeyValue'
    ]),
    ...mapActions([
      'searching'
    ]),
    search (keys) {
      this.setSearchKeys({ keys: keys })
      this.resetSearchResults()
      this.selectSearchKey({ key: 'state' })
      const defaultState = this.currentUser.permissions.is_a_copy_editor ? 'submitted' : 'assigned'
      this.updateSearchKeyValue(
        {
          key: 'state',
          value: [defaultState],
          objValue: null
        }
      )
      this.searching()
    }
  }

}
</script>
