<template>
  <div class="asset-bulk-actions-container">
    <ul
      v-if="hasAccess"
      class="menu submenu is-dropdown-submenu first-sub vertical showActionMenu"
    >
      <li
        v-if="currentUser.permissions.is_a_asset_assigner"
        class="is-submenu-item is-dropdown-submenu-item actionSubMenuTrigger actionReassignTrigger"
      >
        <a @click="selectAction('reassignmentModal')">Reassignment</a>
      </li>
    </ul>
    <reassignment-modal v-if="hasAccess && selectedAction == 'reassignmentModal'"  @closed="closedModal" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ReassignmentModal from './ReassignmentModal'
import BulkActionsMixin from '@/mixins/bulk-actions.js'

export default {
  components: {
    ReassignmentModal
  },
  mixins: [BulkActionsMixin],
  computed: {
    ...mapGetters([
      'currentUser'
    ]),
    hasAccess () {
      return this.currentUser.permissions.is_a_asset_assigner
    }
  }
}
</script>

<style lang="scss" scoped>
.is-dropdown-submenu {
  width: 250px;
}
</style>
