<template>
  <bulk-action-modal @confirmed="changeDate()" @closed="$emit('closed')">
    <template v-slot:title>Update Revised Date</template>

    <template v-slot:appliesTo>
      <span>Applies to {{ assetIds.length }} {{ assetType }}<span v-if="assetIds.length > 1">s</span></span>
    </template>

    <template v-slot:content>
      <div
        v-if="assetIds"
        class="change-revised-date-modal-container"
      >

        <revised-date-modal-contents
          :hideCheckbox="true"
          @revisedChanged="revisedChanged"
        />
      </div>
    </template>
  </bulk-action-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import railsRoutes from '@/store/rails-routes.js'
import BulkActionModal from '@/components/assets/shared/bulk_actions/BulkActionModal.vue'
import BulkActionsMixin from '@/mixins/bulk-actions.js'
import RevisedDateModalContents from '../RevisedDateModalContents.vue'

export default {
  components: {
    BulkActionModal,
    RevisedDateModalContents
  },
  mixins: [BulkActionsMixin],
  data () {
    return {
      saving: false,
      selectedDate: this.$date().format('LL')
    }
  },
  computed: {
    ...mapGetters([
      'assetType'
    ])
  },
  methods: {
    revisedChanged(date)  {
      this.selectedDate = date
    },
    changeDate () {
      if (!this.saving) {
        this.saving = true
        var params = {
          asset_ids: this.assetIds,
          date: this.selectedDate
        }

        axios.post(railsRoutes.api_path({ type: this.assetType + 's', action: 'batch_change_revised_date' }), params).then(response => {
          this.saving = false
          this.$modal.hide('bulk-action-modal')
          this.$notify({ title: "Update Revised Dates Job has been queued", type: 'success' })
        }).catch(error => {
          this.$notify({ title: "Error queing Update Revised Dates Job", type: 'error' })
        })
      }
    }
  },
  mounted () {
    this.updateAssetIds()
  }

}

</script>
<style lang="scss" scoped>
.change-revised-date-modal-container {
}
</style>
