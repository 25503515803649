<template>
  <bulk-action-modal @confirmed="changeStates()" @closed="$emit('closed')">
    <template v-slot:title>Change State</template>

    <template v-slot:appliesTo>
      <span>Applies to {{ assetIds.length }} {{ assetType }}<span v-if="assetIds.length > 1">s</span></span>
    </template>

    <template v-slot:content>
      <div
        v-if="assetIds"
        class="change-state-modal-container"
      >
        <div class="state-selection">
          <label>State</label>
          <select
            v-model="selectedState"
            name="state"
          >
            <option value="null">
              - Select a State -
            </option>
            <option
              v-for="(state, i) in states"
              :key="i"
              :value="state"
              :state="state"
            >
              {{ state }}
            </option>
          </select>
        </div>
      </div>
    </template>
  </bulk-action-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import railsRoutes from '@/store/rails-routes.js'
import BulkActionModal from '@/components/assets/shared/bulk_actions/BulkActionModal.vue'
import BulkActionsMixin from '@/mixins/bulk-actions.js'

export default {
  components: {
    BulkActionModal
  },
  mixins: [BulkActionsMixin],
  data () {
    return {
      saving: false,
      selectedState: null
    }
  },
  computed: {
    states () {
      // Some day we should probably query the states from the api instead of hard coding
      if ((this.assetType == 'topic') || (this.assetType == 'grouping')) {
        return this.$assetStates
      } else {
        return []
      }
    },
    ...mapGetters([
      'selectAll',
      'searchResults',
      'asset',
      'assetType'
    ])
  },
  methods: {
    changeStates () {
      if (!this.saving && this.selectedState) {
        this.saving = true
        var params = {
          asset_ids: this.assetIds,
          state: this.selectedState
        }

        axios.post(railsRoutes.api_path({ type: this.assetType + 's', action: 'batch_change_state' }), params).then(response => {
          this.saving = false
          this.$modal.hide('bulk-action-modal')
          let assetName = this.assetType.charAt(0).toUpperCase() + this.assetType.slice(1)
          this.$notify({ title: `${assetName} States Changed`, type: 'success' })
        })
      }
    }
  },
  mounted () {
    this.updateAssetIds()
  }

}

</script>
<style lang="scss" scoped>
.change-state-modal-container {
  background-color: #FCFBFC;
  padding: 20px;

  .button {
    color: white;
  }

  > label {
    font-weight: 700;
    margin-bottom: 8px;
    font-size: 18px;
  }
  .state-selection {
    width: 200px;
  }
}
</style>
