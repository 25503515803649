<template>
  <bulk-action-modal @confirmed="save()" @closed="$emit('closed')">
    <template v-slot:title>Add/Modify Product Associations</template>

    <template v-slot:appliesTo>
      <span>Applies to {{ assetIds.length }} {{ assetType }}<span v-if="assetIds.length > 1">s</span></span>
    </template>

    <template v-slot:content>
      <div class="add-to-product-modal-container">
        <product-association
          :asset="findAsset()"
          modal-view="true"
        />
      </div>
    </template>
  </bulk-action-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import railsRoutes from '../../../../store/rails-routes'
import ProductAssociation from '../ProductAssociation.vue'
import axios from 'axios'
import BulkActionModal from '@/components/assets/shared/bulk_actions/BulkActionModal.vue'
import BulkActionsMixin from '@/mixins/bulk-actions.js'

export default {
  components: {
    ProductAssociation,
    BulkActionModal
  },
  mixins: [BulkActionsMixin],
  data () {
    return {
      saving: false,
      associations: []
    }
  },
  computed: {
    ...mapGetters([
      'selectAll',
      'searchResults',
      'asset',
      'assetType'
    ])
  },
  methods: {
    findAsset () {
      return { product_associations: this.associations }
    },
    save () {
      if (!this.saving) {
        // Disable save button so it can't be pressed multiple times
        this.saving = true
        var url = railsRoutes.api_path({ type: 'product_associations', action: 'batch_create' })

        var params = {
          asset_ids: this.assetIds,
          asset_type: this.assetType,
          product_associations: this.associations
        }

        axios.post(url, params).then(response => {
          this.saving = false
          this.$modal.hide('bulk-action-modal')
          this.$notify({ title: 'Add/Modify Product Associations Started', type: 'info' })
        })
      }
    }
  },
  mounted () {
    this.updateAssetIds()
  }
}

</script>
<style lang="scss">
.add-to-product-modal-container {
  background-color: #FCFBFC;
  min-height: 500px;
  height: auto;
  padding: 20px;

  .button {
    color: white;
  }

  > label {
    font-weight: 700;
    margin-bottom: 8px;
    font-size: 18px;
  }
}
</style>
