<template>
  <div :class="['flex-table-row', 'attachment-result-view', { 'odd': oddRow }]">
    <div class="flex-table-cell">
      <div class="result-data result-checkbox">
        <input
          :id="&quot;checkbox&quot; + result.id"
          v-model="result.selected"
          class="asset_id"
          name="attachment_id"
          type="checkbox"
          :value="result.id"
        >
        <label
          class="checkbox-label"
          :for="&quot;checkbox&quot; + result.id"
        />
      </div>
    </div>
    <div class="flex-table-cell result-id">
      <div class="result-data-id result-data">
        {{ result.id }}
      </div>
    </div>
    <div class="flex-table-cell result-name">
      <div class="result-data">
        <div
          class="attachment-name"
          v-html="highlight_search_text(result.name, 'name')"
        />
      </div>
    </div>
    <div class="flex-table-cell result-media">
      <div class="result-data">
        <img
          v-if="(result.small_custom_preview_url != null) && (/video/i.test(result.content_content_type))"
          :src="result.small_custom_preview_url"
          alt="Small Custom Preview"
        >

        <img
          v-if="(result.small_content_url != null) && (/image/i.test(result.content_content_type))"
          :src="result.small_content_url"
          alt="Small Content"
        >
      </div>
    </div>
    <div class="flex-table-cell result-apps">
      <div class="result-data apps">
        <div
          v-for="(app, i) in result.apps"
          :key="i"
          class="flex-product"
        >
          <div class="table-data-size app-name">
            {{ app.internal_name | truncate(40) }}
          </div>
        </div>
      </div>
      <div class="fade-text-feature" />
    </div>
    <div class="flex-table-cell">
      <div class="result-data action-button-cell">
        <div class="button-container">
          <button
            class="hlt-button default copy-button hlt-small-button"
            @click="copyToClipboard({selector: '#attachment_url_' + result.id})"
          >
            <input
              v-if="(/image/i.test(result.content_content_type))"
              :id="'attachment_url_' + result.id"
              type="hidden"
              :value="result.large_content_url"
              class="hidden"
            >
            <input
              v-else
              :id="'attachment_url_' + result.id"
              type="hidden"
              :value="result.url"
              class="hidden"
            >
            URL
          </button>
          <edit-button
            route-name="attachment"
            :result="result"
            button-title="Edit"
          />
          <button
            class="hlt-button delete-button warning hlt-small-button"
            @click="deleteRecord(result)"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import railsRoutes from '../../../store/rails-routes'
import EditButton from '../shared/page_components/EditButton'
import highlighter from '../../../mixins/highlighter.js'
export default {
  components: {
    EditButton
  },
  mixins: [
    highlighter
  ],
  props: {
    result: {
      type: Object,
      required: true
    },
    tableIndex: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapGetters([
      'asset',
      'deleteAsset'
    ]),
    oddRow () {
      return this.tableIndex % 2 == 0
    },
  },
  watch: {
    deleteAsset (val, oldVal) {
      if ((val == true) && (this.asset.id == this.result.id)) {
        this.delete({ record: this.asset, url: railsRoutes.api_path({ type: 'attachments' }) }).then(response => {
          this.$modal.hide('delete-modal')
          this.setDeleteAsset({ value: false })
          this.resetSearchResults()
          this.searching()
        })
      }
    }
  },
  methods: {
    deleteRecord (record) {
      this.setAsset({ asset: record })
      if (this.$modal) {
        this.$modal.show('delete-modal')
      }
    },
    ...mapMutations([
      'setAsset',
      'resetSearchResults',
      'setDeleteAsset'
    ]),
    ...mapActions([
      'delete',
      'searching',
      'copyToClipboard'
    ])
  }
}
</script>
<style lang="scss">
.attachment-result-view {
  max-height: 200px;
  overflow: hidden;

  .result-media {
    img {
      max-height: 150px;
    }
  }
  .result-apps {
    position: relative;
    vertical-align: top;
    display: flex;
    flex-direction: column;
    max-height: 200px;
    .flex-product {
      flex-direction: row;
      flex-wrap: wrap;
      padding-bottom: 5px;
    }
  }
  .fade-text-feature {
    position: absolute;
    height: 40%;
    width: 100%;
    bottom: 0;
    left: 0;
    background: -moz-linear-gradient(top,  rgba(247,247,247,0) 0%, rgba(247,247,247,1) 80%, rgba(247,247,247,1) 100%);
    background: -webkit-linear-gradient(top,  rgba(247,247,247,0) 0%,rgba(247,247,247,1) 80%,rgba(247,247,247,1) 100%);
    background: linear-gradient(to bottom,  rgba(247,247,247,0) 0%,rgba(247,247,247,1) 80%,rgba(247,247,247,1) 100%);
  }
  &.odd {
    .fade-text-feature {
      background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 80%, rgba(255,255,255,1) 100%);
      background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 80%,rgba(255,255,255,1) 100%);
      background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 80%,rgba(255,255,255,1) 100%);
    }
  }
  .asset-action-button {
    border: 2px solid #c2c8cc;
    width: 85px !important;
    max-width: none;
    margin: 0.325rem 0;
  }
  .attachment-name {
    display: inline;
  }
}
</style>
