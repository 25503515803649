<template>
  <div class="asset-bulk-actions-container">
    <ul
      v-if="hasAccess"
      class="menu submenu is-dropdown-submenu first-sub vertical showActionMenu"
    >
      <li
        v-if="currentUser.permissions.can_delete_decks"
        class="is-submenu-item is-dropdown-submenu-item actionSubMenuTrigger actionDeleteTrigger"
      >
        <a
          id="show-action"
          @click="selectAction('deleteModal')"
        >Delete Selected</a>
      </li>
      <li
        v-if="currentUser.permissions.can_overwrite_live_decks"
        class="is-submenu-item is-dropdown-submenu-item actionSubMenuTrigger actionOverwriteLiveTrigger"
      >
        <a @click="selectAction('overwriteLiveModal')">Overwrite Live</a>
      </li>
      <li
        v-if="currentUser.permissions.can_write_decks"
        class="is-submenu-item is-dropdown-submenu-item actionSubMenuTrigger actionBatchRevisedDateTrigger"
      >
        <a @click="selectAction('revisedDateModal')">Update Revised Date</a>
      </li>
    </ul>
    <delete-modal v-if="selectedAction == 'deleteModal'"  @closed="closedModal" />
    <overwrite-live-modal v-if="selectedAction == 'overwriteLiveModal'"  @closed="closedModal" />
    <change-revised-date-modal v-if="selectedAction == 'revisedDateModal'"  @closed="closedModal" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DeleteModal from '../../assets/shared/bulk_actions/DeleteAssetsModal.vue'
import OverwriteLiveModal from '../../assets/shared/bulk_actions/OverwriteLiveModal.vue'
import BulkActionsMixin from '@/mixins/bulk-actions.js'
import ChangeRevisedDateModal from '../../assets/shared/bulk_actions/ChangeRevisedDateModal.vue'

export default {
  components: {
    DeleteModal,
    OverwriteLiveModal,
    ChangeRevisedDateModal
  },
  mixins: [BulkActionsMixin],
  computed: {
    ...mapGetters([
      'currentUser'
    ]),
    hasAccess () {
      return this.currentUser.permissions.can_write_decks ||
             this.currentUser.permissions.can_delete_decks ||
             this.currentUser.permissions.can_overwrite_live_decks
    }

  }
}
</script>

<style lang="scss" scoped>
.is-dropdown-submenu {
  width: 250px;
}
</style>
