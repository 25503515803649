<template>
  <div :class="['flex-table-row', 'items-result-view', { 'odd': oddRow }]">
    <div class="center select-all flex-table-cell">
      <div class="result-data result-checkbox">
        <input
          :id="&quot;checkbox&quot; + result.id"
          v-model="result.selected"
          class="asset_id"
          name="item_id"
          type="checkbox"
          :value="result.id"
        >
        <label
          class="checkbox-label"
          :for="&quot;checkbox&quot; + result.id"
        />
      </div>
    </div>
    <div class="flex-table-cell result-id">
      <div class="result-data-id result-data">
        {{ result.id }}
      </div>
    </div>
    <div class="flex-table-cell stem">
      <div class="result-data table-data-size fixed-section">
        <div class="stem-section">
          <div class="stem-content">
            <div class="question-type">
              {{ result.pretty_type }}
            </div>
            <div
              class="question"
              v-html="highlight_search_text(result.sanitized_question, 'question')"
            />
          </div>
        </div>
      </div>
      <div class="fade-text-feature" />
    </div>
    <div class="flex-table-cell explanation">
      <div class="result-data table-data-size fixed-section">
        <div class="explanation-section">
          <div
            class="explanation-content"
            v-html="highlight_search_text(result.sanitized_rationale, 'rationale')"
          />
        </div>
      </div>
      <div class="fade-text-feature" />
    </div>
    <div class="flex-table-cell result-choices">
      <div class="choices-section">
        <div v-if="result.type == 'MultipleChoice' || result.type == 'Sata'">
          <div
            v-for="(choice, i) in sortedChoices"
            :key="i"
            class="result-choice"
            :class="{correct : choice.correct}"
          >
            <div class="flex-choices table-data-size">
              <div
                class="choice-text"
                v-html="highlight_search_text(choice.text, 'answers_raw_content')"
              />
            </div>
          </div>
        </div>
        <div v-if="result.type == 'NumericEntry'">
          <div
            v-for="(choice, i) in result.choices"
            :key="i"
            class="result-choice"
          >
            <div class="flex-choices table-data-size">
              {{ JSON.parse(choice.text).numeric }} {{ JSON.parse(choice.text).unit }}
            </div>
          </div>
        </div>
        <div v-if="result.type == 'FractionalNumericEntry'">
          <div
            v-for="(choice, i) in result.choices"
            :key="i"
            class="result-choice"
          >
            <div class="flex-choices table-data-size">
              {{ JSON.parse(choice.text).numerator }} / {{ JSON.parse(choice.text).denominator }}
            </div>
          </div>
        </div>
        <div v-if="result.type == 'Flipcard'">
          <div class="result-choice">
            <div class="flex-choices table-data-size">
              <div
                class="choice-text"
                v-html="highlight_search_text(result.choices[0].text, 'answers_raw_content')"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="fade-text-feature" />
    </div>
    <div class="flex-table-cell result-csrs">
      <div v-if="result.type == 'MultipleChoice' || result.type == 'Sata'" class="csrs-section">
        <div
          v-for="(choice, i) in sortedCSRs"
          :key="i"
          class="result-csr result-data"
        >
          <div class="flex-rationale table-data-size">
            <div
              class="csr-text"
              v-html="highlight_search_text(choice.rationale || '&nbsp;', 'answers_raw_rationale')"
            />
          </div>
        </div>
      </div>
      <div class="fade-text-feature" />
    </div>
    <div class="flex-table-cell result-apps">
      <div class="result-data apps">
        <div
          v-for="(app, i) in result.apps"
          :key="i"
          class="flex-product"
        >
          <div class="table-data-size app-name">
            {{ app.internal_name | truncate(20) }}
          </div>
        </div>
      </div>
      <div class="fade-text-feature" />
    </div>
    <div class="flex-table-cell actions">
      <div class="result-data action-button-cell">
        <div class="button-container">
          <edit-button
            :route-name="'item'"
            :result="result"
            button-title="Edit"
          />
          <button
            class="hlt-button warning hlt-small-button delete-item"
            @click="currentUser.permissions.can_delete_flashcards && deleteRecord(result)"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import railsRoutes from '../../../store/rails-routes'
import { mixin as clickaway } from 'vue-clickaway2'
import EditButton from '../shared/page_components/EditButton'
import highlighter from '../../../mixins/highlighter.js'

export default {
  props: {
    result: {
      type: Object,
      required: true
    },
    tableIndex: {
      type: Number,
      required: true
    }
  },
  components: {
    EditButton
  },
  mixins: [
    clickaway,
    highlighter
  ],
  computed: {
    ...mapGetters([
      'asset',
      'deleteAsset',
      'selectAll',
      'currentUser'
    ]),
    oddRow () {
      return this.tableIndex % 2 == 0
    },
    iconUrl () {
      return this.result.icon_url
    },
    sortedChoices () {
      return this._.sortBy(this.result.choices, ['sort_order'])
    },
    sortedCSRs () {
      return this._.sortBy(this.result.choices, ['correct_order'])
    }
  },
  watch: {
    deleteAsset (val, oldVal) {
      if ((val === true) && (this.asset.id == this.result.id)) {
        this.delete({ record: this.asset, url: railsRoutes.api_path({ type: 'items' }) }).then(response => {
          this.$notify({ title: 'Item Deleted', type: 'success' })
          this.$modal.hide('delete-modal')
          this.setDeleteAsset({ value: false })
          this.resetSearchResults()
          this.searching()
        })
      }
    }
  },
  methods: {
    deleteRecord (record) {
      this.setAsset({ asset: record })
      if (this.$modal) {
        this.$modal.show('delete-modal')
      }
    },
    ...mapMutations(['setAsset', 'setDeleteAsset', 'resetSearchResults']),
    ...mapActions(['delete', 'searching'])
  }
}
</script>

<style lang="scss" scoped>
.items-result-view {
  max-height: 200px;
  overflow: hidden;

  .stem, .explanation, .result-choices, .result-csrs, .result-apps {
    position: relative;
  }
  .result-choices, .result-csrs {
    vertical-align: top;
    font-size: 12px;
    max-height: 200px;
    overflow: hidden;
  }
  .fixed-section {
    padding: 0 !important;
  }
  .result-choice, .result-csr {
    margin: 0;
  }
  .result-csr {
    padding: 0 !important;
  }
  .flex-choices, .flex-rationale, .flex-product {
    display: flex;
  }
  .flex-choices, .flex-rationale {
    padding: .5rem .75rem;
  }
  .flex-product {
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 5px;
  }
  .choice-text, .csr-text {
    display: inline;
  }

  .table-data-size {
    font-size: .875rem;
  }
  .stem-section, .explanation-section {
    min-height: 11rem;
  }
  .stem-content, .explanation-content {
    padding: 1rem;
    overflow: hidden;
    height: 100%;
    max-height: 11rem;
  }
  .fade-text-feature {
    position: absolute;
    height: 40%;
    width: 100%;
    bottom: 0;
    left: 0;
    background: -moz-linear-gradient(top,  rgba(247,247,247,0) 0%, rgba(247,247,247,1) 80%, rgba(247,247,247,1) 100%);
    background: -webkit-linear-gradient(top,  rgba(247,247,247,0) 0%,rgba(247,247,247,1) 80%,rgba(247,247,247,1) 100%);
    background: linear-gradient(to bottom,  rgba(247,247,247,0) 0%,rgba(247,247,247,1) 80%,rgba(247,247,247,1) 100%);
  }
  &.odd {
    .fade-text-feature {
      background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 80%, rgba(255,255,255,1) 100%);
      background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 80%,rgba(255,255,255,1) 100%);
      background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 80%,rgba(255,255,255,1) 100%);
    }
  }
  .correct {
    font-weight: bold;
  }
  .result-apps {
    vertical-align: top;
    display: flex;
    flex-direction: column;
    max-height: 200px;
  }
  .stem {
    vertical-align: top;
    .question-type {
      color: $grey-1;
    }
  }
  .explanation {
    vertical-align: top;
    .explanation-placeholder {
      color: $grey-1;
    }
  }
  .asset-action-button {
    border: 2px solid $grey-3;
    width: 85px !important;
    max-width: none;
    margin: 0.325rem 0;
  }
  .choices-section, .csrs-section {
    padding-top: 10px !important;
  }
}
</style>
