<template>
  <bulk-action-modal @confirmed="save()" @closed="$emit('closed')">
    <template v-slot:title>Duplicate {{ assetType }}</template>

    <template v-slot:appliesTo>
      <span>Applies to {{ assetIds.length }} {{ assetType }}<span v-if="assetIds.length > 1">s</span></span>
    </template>

    <template v-slot:content>
      <div class="duplicate-asset-modal-container">
        <div class="duplicate-notice">
          <h4>Optional</h4>
        </div>
        <product-association
          :asset="findAsset()"
          :hide-order="true"
          :hide-visibility="true"
        />
      </div>
    </template>
  </bulk-action-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import railsRoutes from '@/store/rails-routes.js'
import axios from 'axios'
import ProductAssociation from '../ProductAssociation.vue'
import BulkActionModal from '@/components/assets/shared/bulk_actions/BulkActionModal.vue'
import BulkActionsMixin from '@/mixins/bulk-actions.js'

export default {
  components: {
    ProductAssociation,
    BulkActionModal
  },
  mixins: [BulkActionsMixin],
  data () {
    return {
      saving: false,
      associations: []
    }
  },
  computed: {
    ...mapGetters([
      'assetType'
    ])
  },
  methods: {
    findAsset () {
      return { product_associations: this.associations }
    },
    save () {
      if (!this.saving) {
        // Disable save button so it can't be pressed multiple times
        this.saving = true
        var url = railsRoutes.api_path({ type: this.assetType + 's', action: 'batch_dupe' })

        var params = {
          asset_ids: this.assetIds,
          product_associations: this.associations
        }

        axios.post(url, params).then(response => {
          this.saving = false
          let name = this.assetType.charAt(0).toUpperCase() + this.assetType.slice(1)
          const msg = `${name} Duplication Started...`
          this.$modal.hide('bulk-action-modal')
          this.$notify({ title: msg, type: 'success' })
        })
      }
    },
    clearModalData () {
      this.saving = false
      this.associations = []
    }
  },
  mounted () {
    this.updateAssetIds()
  }
}

</script>
<style lang="scss" scoped>
.duplicate-asset-modal-container {
  margin: 0;
  padding: 20px;
  min-height: 500px;
  background-color: $white-2;
  height: auto;
  overflow: scroll;
  .button {
    color: $white-2;
  }

  > label {
    font-weight: 700;
    margin-bottom: 8px;
    font-size: 18px;
  }
  > select {
    width: 75%;
  }
  .duplicate-notice {
    font-size: 1rem;
    font-weight: 400;
    color: $space-black;
  }
}
</style>
