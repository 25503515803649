<template>
  <div>
    <ul class="dropdown menu actions-dropdown shrink">
      <li
        role="menuitem"
        class="is-dropdown-submenu-parent opens-right actionMenuTrigger"
      >
        <a
          href="#"
          class="triggerAnchor"
        >
          <span class="lightning">
            <svg
              width="14"
              height="20"
              viewBox="0 0 14 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M.541 20a.51.51 0 0 1-.376-.172.668.668 0 0 1-.057-.803l5.115-7.7H2.256c-.23 0-.434-.163-.51-.407a.66.66 0 0 1 .172-.679L13.12.134a.496.496 0 0 1 .714.038c.196.213.22.558.057.803l-5.116 7.7h2.968c.23 0 .435.163.51.407a.659.659 0 0 1-.172.678L.88 19.866A.502.502 0 0 1 .541 20z"
                fill="#FAC257"
                fill-rule="evenodd"
              />
            </svg>
          </span>
          <span class="actions-title">Actions</span>
          <span class="down-arrow">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="10"
              viewBox="0 0 18 10"
            >
              <path
                fill="#8A9399"
                fill-rule="nonzero"
                d="M15.43.725a.654.654 0 0 1 .947.128l.934 1.311c.204.287.143.7-.133.925L9.556 9.277a.709.709 0 0 1-.89.015L.818 3.09a.672.672 0 0 1-.134-.914L1.601.851A.661.661 0 0 1 2.55.713l6.535 5.163L15.429.725z"
              />
            </svg>
          </span>
        </a>
        <div v-if="!keysHaveBeenModified">
          <topic-bulk-actions v-if="this.$route.name === 'topics'" />
          <attachment-bulk-actions v-if="this.$route.name === 'attachments'" />
          <items-bulk-actions v-if="this.$route.name === 'items'" />
          <groupings-bulk-actions v-if="this.$route.name === 'groupings'" />
          <assignments-bulk-actions v-if="this.$route.name === 'asset_assignments'" />
          <learning-modules-bulk-actions v-if="this.$route.name === 'learning_modules'" />
          <decks-bulk-actions v-if="this.$route.name === 'decks'" />
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import AttachmentBulkActions from '../attachments/BulkActions.vue'
import TopicBulkActions from '../topics/BulkActions.vue'
import ItemsBulkActions from '../items/BulkActions.vue'
import GroupingsBulkActions from '../groupings/BulkActions.vue'
import AssignmentsBulkActions from '../../publishing/assignments/BulkActions.vue'
import LearningModulesBulkActions from '../learning_modules/BulkActions.vue'
import DecksBulkActions from '../../app_manager/decks/BulkActions.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    AttachmentBulkActions,
    TopicBulkActions,
    ItemsBulkActions,
    GroupingsBulkActions,
    AssignmentsBulkActions,
    LearningModulesBulkActions,
    DecksBulkActions
  },
  computed: {
    ...mapGetters([
      'keysHaveBeenModified'
    ])
  }
}
</script>
<style lang="scss">
.actionMenuTrigger {
  .triggerAnchor {
    padding: 0.5rem 1.5rem 0.85rem 1rem !important;
    margin: 0;
    letter-spacing: .25px;
    line-height: 1 !important;
  }
  .actionSubMenuTrigger {
    line-height: 1.6;
    padding: 0.25rem 1rem;
    letter-spacing: 0.02rem;
  }
  &:hover .showActionMenu {
    display: block !important;
    top: auto !important;
    left: 0;
    z-index: 201;
  }
}

.actionSubMenuTrigger:hover a {
  color: #69B7DA;
}

.asset-bulk-actions-container {
  display: inline;
  position: relative;
  z-index: 999;
}
</style>
