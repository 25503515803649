<template>
  <div class="asset-bulk-actions-container">
    <ul
      v-if="hasAccess"
      class="menu submenu is-dropdown-submenu first-sub vertical showActionMenu"
    >
      <li
        v-if="currentUser.permissions.can_write_topics"
        class="is-submenu-item is-dropdown-submenu-item actionSubMenuTrigger actionCreateTrigger"
      >
        <a @click="selectAction('createTopicsModal')">Create Topics</a>
      </li>
      <li
        v-if="currentUser.permissions.can_write_topics_product_associations"
        class="is-submenu-item is-dropdown-submenu-item actionSubMenuTrigger actionProductTrigger"
      >
        <a @click="selectAction('addToProductModal')">Add/Modify Product</a>
      </li>
      <li
        v-if="currentUser.permissions.can_delete_topics"
        class="is-submenu-item is-dropdown-submenu-item actionSubMenuTrigger actionDeleteTrigger"
      >
        <a @click="selectAction('deleteModal')">Delete Selected</a>
      </li>
      <li
        v-if="currentUser.permissions.can_overwrite_live_topics"
        class="is-submenu-item is-dropdown-submenu-item actionSubMenuTrigger actionOverwriteLiveTrigger"
      >
        <a @click="selectAction('overwriteLiveModal')">Overwrite Live</a>
      </li>
      <li
        v-if="currentUser.permissions.can_write_topics"
        class="is-submenu-item is-dropdown-submenu-item actionSubMenuTrigger actionChangeStateTrigger"
      >
        <a @click="selectAction('changeStateModal')">Change State</a>
      </li>
      <li
        v-if="currentUser.permissions.can_write_topics"
        class="is-submenu-item is-dropdown-submenu-item actionSubMenuTrigger actionDuplicateTrigger"
      >
        <a @click="selectAction('duplicateModal')">Duplicate Topic</a>
      </li>
      <li
        v-if="currentUser.permissions.is_a_asset_assigner"
        class="is-submenu-item is-dropdown-submenu-item actionSubMenuTrigger actionAssignTrigger"
      >
        <a @click="selectAction('assignmentsModal')">Assign Selected</a>
      </li>
      <li
        v-if="currentUser.permissions.can_write_topics"
        class="is-submenu-item is-dropdown-submenu-item actionSubMenuTrigger actionBatchChangeContentSourceTrigger"
      >
        <a @click="selectAction('contentSourceModal')">Change Content Source</a>
      </li>
      <li
        v-if="currentUser.permissions.can_write_topics"
        class="is-submenu-item is-dropdown-submenu-item actionSubMenuTrigger actionBatchAddTagTrigger"
      >
        <a @click="selectAction('addTagsModal')">Add Tags</a>
      </li>
      <li
        v-if="currentUser.permissions.can_write_topics"
        class="is-submenu-item is-dropdown-submenu-item actionSubMenuTrigger actionBatchRemoveTagTrigger"
      >
        <a @click="selectAction('removeTagsModal')">Remove Tags</a>
      </li>
      <li
        v-if="currentUser.permissions.can_write_topics"
        class="is-submenu-item is-dropdown-submenu-item actionSubMenuTrigger actionBatchRevisedDateTrigger"
      >
        <a @click="selectAction('revisedDateModal')">Update Revised Date</a>
      </li>
    </ul>

    <overwrite-live-modal v-if="selectedAction == 'overwriteLiveModal'"  @closed="closedModal" />
    <change-state-modal v-if="selectedAction == 'changeStateModal'"  @closed="closedModal" />
    <add-to-product-modal v-if="selectedAction == 'addToProductModal'"  @closed="closedModal" />
    <delete-modal v-if="selectedAction == 'deleteModal'" @closed="closedModal" />
    <duplicate-asset-modal v-if="selectedAction == 'duplicateModal'" @closed="closedModal" />
    <asset-assignment-modal v-if="selectedAction == 'assignmentsModal'"  @closed="closedModal" />
    <change-content-source-modal v-if="selectedAction == 'contentSourceModal'"  @closed="closedModal" />
    <add-tag-modal v-if="selectedAction == 'addTagsModal'"  @closed="closedModal" />
    <remove-tag-modal v-if="selectedAction == 'removeTagsModal'"  @closed="closedModal" />
    <change-revised-date-modal v-if="selectedAction == 'revisedDateModal'"  @closed="closedModal" />
    <create-topics-modal v-if="selectedAction == 'createTopicsModal'" @closed="closedModal" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AddToProductModal from '../shared/bulk_actions/AddToProductModal.vue'
import DeleteModal from '../shared/bulk_actions/DeleteAssetsModal.vue'
import OverwriteLiveModal from '../shared/bulk_actions/OverwriteLiveModal.vue'
import ChangeStateModal from '../shared/bulk_actions/ChangeStateModal.vue'
import BulkActionsMixin from '@/mixins/bulk-actions.js'
import AssetAssignmentModal from '../shared/AssetAssignmentModal'
import ChangeContentSourceModal from '../shared/bulk_actions/ChangeContentSourceModal.vue'
import AddTagModal from '../shared/bulk_actions/AddTagModal.vue'
import RemoveTagModal from '../shared/bulk_actions/RemoveTagModal.vue'
import DuplicateAssetModal from '../shared/bulk_actions/DuplicateAssetModal.vue'
import ChangeRevisedDateModal from '../shared/bulk_actions/ChangeRevisedDateModal.vue'
import CreateTopicsModal from './CreateTopicsModal.vue'

export default {
  components: {
    AddToProductModal,
    DeleteModal,
    OverwriteLiveModal,
    ChangeStateModal,
    AssetAssignmentModal,
    ChangeContentSourceModal,
    AddTagModal,
    RemoveTagModal,
    DuplicateAssetModal,
    ChangeRevisedDateModal,
    CreateTopicsModal
  },
  mixins: [BulkActionsMixin],
  computed: {
    ...mapGetters([
      'currentUser'
    ]),
    hasAccess () {
      return this.currentUser.permissions.can_write_topics_product_associations ||
             this.currentUser.permissions.can_write_topics ||
             this.currentUser.permissions.can_delete_topics ||
             this.currentUser.permissions.can_overwrite_live_topics ||
             this.currentUser.permissions.is_a_asset_assigner
    }
  }
}
</script>

<style lang="scss" scoped>
.is-dropdown-submenu {
  width: 250px;
}
</style>
