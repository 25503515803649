<template>
  <div class="shared-table-container">
    <div
      class="flex-table asset-table"
      :class="tableClass()"
    >
      <div class="flex-table-head asset-table-head">
        <topic-search-header v-if="this.$route.name == 'topics'" />
        <attachment-search-header v-else-if="this.$route.name == 'attachments'" />
        <item-search-header v-else-if="this.$route.name == 'items'" />
        <grouping-search-header v-else-if="this.$route.name == 'groupings'" />
        <learning-module-search-header v-else-if="this.$route.name == 'learning_modules'" />
        <tag-search-header v-else-if="this.$route.name == 'tags'" />
        <content-source-search-header v-else-if="this.$route.name == 'content-sources'" />
        <deck-search-header v-else-if="this.$route.name == 'decks'" />
        <catalog-search-header v-else-if="this.$route.name == 'catalogs'" />
        <catalog-page-search-header v-else-if="this.$route.name == 'catalog_pages'" />
        <discussion-search-header v-else-if="this.$route.name == 'discussions'" />
        <assignment-search-header v-else-if="this.$route.name == 'asset_assignments'" />
        <admin-lms-user-search-header v-else-if="this.$route.name == 'admin-lms-users'" />
      </div>
      <div class="flex-table-body scroll-content">
        <loader v-if="searchIsActive" />
        <div
          v-if="!searchIsActive && searchTotal == 0"
          class="search_result flex-table-row"
        >
          <div class="flex-table-cell" />
          <div class="flex-table-cell no-results">
            No Results
          </div>
        </div>
        <topic-search-result
          v-for="(result, index) in searchResults"
          v-else-if="this.$route.name == 'topics'"
          :id="'asset_result' + result.id"
          :key="result.id"
          :result="result"
          :table-index="index"
        />
        <admin-lms-user-search-result
          v-for="result in searchResults"
          v-else-if="this.$route.name == 'admin-lms-users'"
          :id="'asset_result' + result.id"
          :key="result.id"
          :result="result"
        />
        <attachment-search-result
          v-for="(result, index) in searchResults"
          v-else-if="this.$route.name == 'attachments'"
          :id="'asset_result' + result.id"
          :key="result.id"
          :result="result"
          :table-index="index"
        />
        <item-search-result
          v-for="(result, index) in searchResults"
          v-else-if="this.$route.name == 'items'"
          :id="'asset_result' + result.id"
          :key="result.id"
          :result="result"
          :table-index="index"
        />
        <grouping-search-result
          v-for="result in searchResults"
          v-else-if="this.$route.name == 'groupings'"
          :id="'asset_result' + result.id"
          :key="result.id"
          :result="result"
        />
        <learning-module-search-result
          v-for="result in searchResults"
          v-else-if="this.$route.name == 'learning_modules'"
          :id="'asset_result' + result.id"
          :key="result.id"
          :result="result"
        />
        <tag-search-result
          v-for="result in searchResults"
          v-else-if="this.$route.name == 'tags'"
          :id="'asset_result' + result.id"
          :key="result.id"
          :result="result"
        />
        <content-source-search-result
          v-for="result in searchResults"
          v-else-if="this.$route.name == 'content-sources'"
          :id="'asset_result' + result.id"
          :key="result.id"
          :result="result"
        />
        <catalog-search-result
          v-for="result in searchResults"
          v-else-if="this.$route.name == 'catalogs'"
          :id="'asset_result' + result.id"
          :key="result.id"
          :result="result"
        />
        <catalog-page-search-result
          v-for="result in searchResults"
          v-else-if="this.$route.name == 'catalog_pages'"
          :id="'asset_result' + result.id"
          :key="result.id"
          :result="result"
        />
        <deck-search-result
          v-for="result in searchResults"
          v-else-if="this.$route.name == 'decks'"
          :id="'asset_result' + result.id"
          :key="result.id"
          :result="result"
        />
        <discussion-search-result
          v-for="result in searchResults"
          v-else-if="this.$route.name == 'discussions'"
          :id="'asset_result' + result.id"
          :key="result.id"
          :result="result"
        />
        <assignment-search-result
          v-for="result in searchResults"
          v-else-if="this.$route.name == 'asset_assignments'"
          :id="'asset_result' + result.id"
          :key="result.id"
          :result="result"
        />

        <div
          class="fetch-content-button"
          v-if="!searchIsActive && ((searchTotal != 0) && (searchResults.length < searchTotal))"
        >
          <button
            :disabled="loading"
            @click="fetchNextPage"
            class="hlt-button secondary hlt-small-button"
          >
            <span v-if="!loading">
              Load More
            </span>
            <span v-else>
              Loading...
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import TopicSearchHeader from '../assets/topics/SearchHeader'
import TopicSearchResult from '../assets/topics/SearchResult'

import AttachmentSearchHeader from '../assets/attachments/SearchHeader'
import AttachmentSearchResult from '../assets/attachments/SearchResult'

import ItemSearchHeader from '../assets/items/SearchHeader'
import ItemSearchResult from '../assets/items/SearchResult'

import GroupingSearchHeader from '../assets/groupings/SearchHeader'
import GroupingSearchResult from '../assets/groupings/SearchResult'

import LearningModuleSearchHeader from '../assets/learning_modules/SearchHeader'
import LearningModuleSearchResult from '../assets/learning_modules/SearchResult'

import TagSearchHeader from '../settings/tags/SearchHeader'
import TagSearchResult from '../settings/tags/SearchResult'

import ContentSourceSearchHeader from '../settings/content_sources/SearchHeader'
import ContentSourceSearchResult from '../settings/content_sources/SearchResult'

import CatalogSearchHeader from '../app_manager/catalogs/SearchHeader'
import CatalogSearchResult from '../app_manager/catalogs/SearchResult'

import CatalogPageSearchHeader from '../app_manager/catalog_pages/SearchHeader'
import CatalogPageSearchResult from '../app_manager/catalog_pages/SearchResult'

import DeckSearchHeader from '../app_manager/decks/SearchHeader'
import DeckSearchResult from '../app_manager/decks/SearchResult'

import DiscussionSearchHeader from '../publishing/discussions/SearchHeader'
import DiscussionSearchResult from '../publishing/discussions/SearchResult'

import AssignmentSearchHeader from '../publishing/assignments/SearchHeader'
import AssignmentSearchResult from '../publishing/assignments/SearchResult'

import AdminLmsUserSearchHeader from '../admin/lms-users/SearchHeader.vue'
import AdminLmsUserSearchResult from '../admin/lms-users/SearchResult.vue'


import Loader from '../assets/shared/Loader'
export default {
  components: {
    TopicSearchResult,
    TopicSearchHeader,
    AttachmentSearchResult,
    AttachmentSearchHeader,
    ItemSearchResult,
    ItemSearchHeader,
    GroupingSearchResult,
    GroupingSearchHeader,
    LearningModuleSearchResult,
    LearningModuleSearchHeader,
    TagSearchHeader,
    TagSearchResult,
    ContentSourceSearchHeader,
    ContentSourceSearchResult,
    CatalogSearchHeader,
    CatalogSearchResult,
    CatalogPageSearchHeader,
    CatalogPageSearchResult,
    DeckSearchHeader,
    DeckSearchResult,
    DiscussionSearchHeader,
    DiscussionSearchResult,
    AssignmentSearchHeader,
    AssignmentSearchResult,
    AdminLmsUserSearchHeader,
    AdminLmsUserSearchResult,
    Loader
  },
  mutations: mapMutations([
    'setTopLevelAsset'
  ]),
  data () {
    return {
      search_limit: 1500, // number of milliseconds to allow subsequent queries
      lastSearch: (new Date()).getTime(),
      loading: false,
      lastAsset: null
    }
  },
  computed: {
    ...mapGetters([
      'searchResults',
      'searchTotal',
      'searchIsActive',
      'currentUser'
    ]),
    isManager () {
      return this.currentUser.permissions.is_a_asset_assigner
    }
  },
  mounted () {
    const t = this.getTableElement()
    if (t) {
      t.addEventListener('scroll', this.scrolling)
    }
  },
  destroyed () {
    const t = this.getTableElement()
    if (t) {
      t.removeEventListener('scroll', this.scrolling)
    }
  },
  methods: {
    ...mapActions([
      'edit',
      'delete',
      'searching'
    ]),
    ...mapMutations([
      'resetSearchResults',
      'incrementSearchPage'
    ]),
    getTableElement () {
      const table = document.querySelectorAll('.asset-table')[0]
      return table
    },
    tableClass () {
      if (this.$route.name === 'topics') {
        return 'topic_results'
      } else if (this.$route.name === 'attachments') {
        return 'attachment_results'
      } else if (this.$route.name === 'items') {
        return 'item_results'
      } else if (this.$route.name === 'groupings') {
        return 'grouping_results'
      } else if (this.$route.name === 'learning_modules') {
        return 'learning_modules_results'
      } else if (this.$route.name === 'tags') {
        return 'tag_results'
      } else if (this.$route.name === 'content-sources') {
        return 'content_sources_results'
      } else if (this.$route.name === 'catalogs') {
        return 'catalog_results'
      } else if (this.$route.name === 'catalog_pages') {
        return 'catalog_page_results'
      } else if (this.$route.name === 'admin-lms-users') {
        return 'admin-lms-users-results'
      } else if (this.$route.name === 'decks') {
        return 'deck_results'
      } else if (this.$route.name === 'discussions') {
        return 'discussion_results'
      } else if (this.$route.name === 'asset_assignments' && this.isManager) {
        return 'assignment_manager_results'
      } else if (this.$route.name === 'asset_assignments') {
        return 'assignment_results'
      }
    },
    fetchNextPage () {
      if (!this.loading) {
        this.loading = true
        this.lastAsset = this.searchResults[this.searchResults.length - 1]
        this.incrementSearchPage()
        this.searching().catch(error => {
          console.error('Error fetching search results', error)
        }).finally(() => {
          this.lastSearch = (new Date()).getTime()
          this.loading = false
          // Keep the last record in the visible area so they can see where they left off`
          const lastAssetInDom = document.getElementById(`asset_result${this.lastAsset.id}`)
          lastAssetInDom.scrollIntoView()
        })
      }
    },
    scrolling () {
      const tbody = document.getElementsByClassName('scroll-content')[0]
      const table = this.getTableElement()
      if ((Object.keys(this.searchResults).length < this.searchTotal)
         && (Object.keys(this.searchResults).length > 0)) {
        if (table.scrollTop + tbody.clientHeight === tbody.scrollHeight) { // WORKS IN FF, CHROME, SAFARI
          if ((this.lastSearch + this.search_limit) < (new Date()).getTime()) {
            this.fetchNextPage()
          }
        }
      }
    }
  }
}
</script>
<style lang="scss">
.shared-table-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  border: solid 1px $grey-3;
  border-radius: 3px;
  font-size: .875rem;
  .flex-table {
    position: relative;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: auto;

    .flex-table-head {
      position: sticky;
      top: 0;
      display: flex;
      border-bottom: solid 1px $grey-3;
      z-index: 200;
    }
    .flex-table-body {
      height: 70vh;
    }
    .flex-table-row {
      display: flex;
      flex-direction: row;
      flex-grow: 0;
      flex-wrap: wrap;
      border-bottom: solid 1px $grey-3;
      &:nth-child(even) {
        background-color: $white-1;
      }
      .flex-header, .flex-table-cell {
        border-right: solid 1px $grey-3;
        padding: 0;
        margin: 0;
        &:last-child {
          border: 0;
        }
      }
      .flex-table-cell {
        &.result-choices {
          padding: 0;
        }
        @media only screen and (max-width: 800px) {
          display: block;
        }
      }
      .flex-table-cell .disabled-cell {
        background-color: $grey-3;
      }
    }
  }
}

.scroll-content {
  .fetch-content-button {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 10px;
    button {
      align-self: center;
    }
  }
}

.no-results {
  text-align: center;
}
.attachment_results {
  .flex-header:nth-child(1), .flex-table-cell:nth-child(1) { display: flex; flex: 1; overflow: hidden; }
  .flex-header:nth-child(2), .flex-table-cell:nth-child(2) { display: flex; flex: 1; overflow: hidden; }
  .flex-header:nth-child(3), .flex-table-cell:nth-child(3) { display: flex; flex: 5; overflow: hidden; }
  .flex-header:nth-child(4), .flex-table-cell:nth-child(4) { display: flex; flex: 4; overflow: hidden; }
  .flex-header:nth-child(5), .flex-table-cell:nth-child(5) { display: flex; flex: 4; overflow: hidden; flex-direction: column;}
  .flex-header:nth-child(6), .flex-table-cell:nth-child(6) { display: flex; flex: 2; overflow: hidden; min-width: 100px;}
}
.item_results {
  .flex-header:nth-child(1), .flex-table-cell:nth-child(1) { display: flex; flex: 1; overflow: hidden; }
  .flex-header:nth-child(2), .flex-table-cell:nth-child(2) { display: flex; flex: 2; overflow: hidden; }
  .flex-header:nth-child(3), .flex-table-cell:nth-child(3) { display: flex; flex: 5; overflow: hidden; }
  .flex-header:nth-child(4), .flex-table-cell:nth-child(4) { display: flex; flex: 5; overflow: hidden; }
  .flex-header:nth-child(5), .flex-table-cell:nth-child(5) { display: flex; flex: 4; overflow: hidden; }
  .flex-header:nth-child(6), .flex-table-cell:nth-child(6) { display: flex; flex: 4; overflow: hidden; flex-direction: column;}
  .flex-header:nth-child(7), .flex-table-cell:nth-child(7) { display: flex; flex: 3; overflow: hidden; }
  .flex-header:nth-child(8), .flex-table-cell:nth-child(8) { display: flex; flex: 2; overflow: hidden; min-width: 100px;}
}
.topic_results {
  .flex-header:nth-child(1), .flex-table-cell:nth-child(1) { display: flex; flex: 1; overflow: hidden;}
  .flex-header:nth-child(2), .flex-table-cell:nth-child(2) { display: flex; flex: 2; overflow: hidden;}
  .flex-header:nth-child(3), .flex-table-cell:nth-child(3) { display: flex; flex: 6; overflow: hidden;}
  .flex-header:nth-child(4), .flex-table-cell:nth-child(4) { display: flex; flex: 4; overflow: hidden; flex-direction: column;}
  .flex-header:nth-child(5), .flex-table-cell:nth-child(5) { display: flex; flex: 1; overflow: hidden; min-width: 100px;}
}
.grouping_results {
  .flex-header:nth-child(1), .flex-table-cell:nth-child(1) { display: flex; flex: 1; overflow: hidden;}
  .flex-header:nth-child(2), .flex-table-cell:nth-child(2) { display: flex; flex: 1; overflow: hidden;}
  .flex-header:nth-child(3), .flex-table-cell:nth-child(3) { display: flex; flex: 2; overflow: hidden;}
  .flex-header:nth-child(4), .flex-table-cell:nth-child(4) { display: flex; flex: 4; overflow: hidden;}
  .flex-header:nth-child(5), .flex-table-cell:nth-child(5) { display: flex; flex: 1; overflow: hidden; min-width: 100px;}
}
.learning_modules_results {
  .flex-header:nth-child(1), .flex-table-cell:nth-child(1) { display: flex; flex: 1; overflow: hidden;}
  .flex-header:nth-child(2), .flex-table-cell:nth-child(2) { display: flex; flex: 1; overflow: hidden; min-width: 100px;}
  .flex-header:nth-child(3), .flex-table-cell:nth-child(3) { display: flex; flex: 3; overflow: hidden;}
  .flex-header:nth-child(4), .flex-table-cell:nth-child(4) { display: flex; flex: 2; overflow: hidden;}
  .flex-header:nth-child(5), .flex-table-cell:nth-child(5) { display: flex; flex: 1; overflow: hidden; min-width: 100px;}
}
.tag_results, .content_sources_results {
  .flex-header:nth-child(1), .flex-table-cell:nth-child(1) { display: flex; flex: 1; overflow: hidden; max-width: 100px;}
  .flex-header:nth-child(2), .flex-table-cell:nth-child(2) { display: flex; flex: 1; overflow: hidden;}
  .flex-header:nth-child(3), .flex-table-cell:nth-child(3) { display: flex; flex: 6; overflow: hidden;}
  .flex-header:nth-child(4), .flex-table-cell:nth-child(4) { display: flex; flex: 1; overflow: hidden; min-width: 100px;}
}

.catalog_results {
  .flex-header:nth-child(1), .flex-table-cell:nth-child(1) { display: flex; flex: 1; overflow: hidden; max-width: 100px;}
  .flex-header:nth-child(2), .flex-table-cell:nth-child(2) { display: flex; flex: 1; overflow: hidden;}
  .flex-header:nth-child(3), .flex-table-cell:nth-child(3) { display: flex; flex: 2; overflow: hidden;}
  .flex-header:nth-child(4), .flex-table-cell:nth-child(4) { display: flex; flex: 1; overflow: hidden; min-width: 100px;}
}

.catalog_page_results {
  .flex-header:nth-child(1), .flex-table-cell:nth-child(1) { display: flex; flex: 1; overflow: hidden; max-width: 100px;}
  .flex-header:nth-child(2), .flex-table-cell:nth-child(2) { display: flex; flex: 1; overflow: hidden;}
  .flex-header:nth-child(3), .flex-table-cell:nth-child(3) { display: flex; flex: 2; overflow: hidden;}
  .flex-header:nth-child(4), .flex-table-cell:nth-child(4) { display: flex; flex: 2; overflow: hidden;}
  .flex-header:nth-child(5), .flex-table-cell:nth-child(5) { display: flex; flex: 1; overflow: hidden; min-width: 100px;}
}

.deck_results {
  .flex-header:nth-child(1), .flex-table-cell:nth-child(1) { display: flex; flex: 1; overflow: hidden; max-width: 100px;}
  .flex-header:nth-child(2), .flex-table-cell:nth-child(2) { display: flex; flex: 1; overflow: hidden;}
  .flex-header:nth-child(3), .flex-table-cell:nth-child(3) { display: flex; flex: 3; overflow: hidden;}
  .flex-header:nth-child(4), .flex-table-cell:nth-child(4) { display: flex; flex: 3; overflow: hidden;}
  .flex-header:nth-child(5), .flex-table-cell:nth-child(5) { display: flex; flex: 1; overflow: hidden; max-width: 100px;}
}

.discussion_results {
  .flex-header:nth-child(1), .flex-table-cell:nth-child(1) { display: flex; flex: 1; overflow: hidden; max-width: 100px;}
  .flex-header:nth-child(2), .flex-table-cell:nth-child(2) { display: flex; flex: 1; overflow: hidden;}
  .flex-header:nth-child(3), .flex-table-cell:nth-child(3) { display: flex; flex: 1; overflow: hidden;}
  .flex-header:nth-child(4), .flex-table-cell:nth-child(4) { display: flex; flex: 2; overflow: hidden;}
  .flex-header:nth-child(5), .flex-table-cell:nth-child(5) { display: flex; flex: 4; overflow: hidden;}
  .flex-header:nth-child(6), .flex-table-cell:nth-child(6) { display: flex; flex: 2; overflow: hidden;}
  .flex-header:nth-child(7), .flex-table-cell:nth-child(7) { display: flex; flex: 1; overflow: hidden; max-width: 140px;}
}
.assignment_manager_results {
  .flex-header:nth-child(1), .flex-table-cell:nth-child(1) { display: flex; flex: 1; overflow: hidden; max-width: 100px;}
  .flex-header:nth-child(2), .flex-table-cell:nth-child(2) { display: flex; flex: 1; overflow: hidden;}
  .flex-header:nth-child(3), .flex-table-cell:nth-child(3) { display: flex; flex: 1; overflow: hidden;}
  .flex-header:nth-child(4), .flex-table-cell:nth-child(4) { display: flex; flex: 1; overflow: hidden;}
  .flex-header:nth-child(5), .flex-table-cell:nth-child(5) { display: flex; flex: 1; overflow: hidden;}
  .flex-header:nth-child(6), .flex-table-cell:nth-child(6) { display: flex; flex: 4; overflow: hidden;}
  .flex-header:nth-child(7), .flex-table-cell:nth-child(7) { display: flex; flex: 3; overflow: hidden; max-width: 230px;}
}
.assignment_results {
  .flex-header:nth-child(1), .flex-table-cell:nth-child(1) { display: flex; flex: 1; overflow: hidden; max-width: 100px;}
  .flex-header:nth-child(2), .flex-table-cell:nth-child(2) { display: flex; flex: 1; overflow: hidden;}
  .flex-header:nth-child(3), .flex-table-cell:nth-child(3) { display: flex; flex: 1; overflow: hidden;}
  .flex-header:nth-child(4), .flex-table-cell:nth-child(4) { display: flex; flex: 4; overflow: hidden;}
  .flex-header:nth-child(5), .flex-table-cell:nth-child(5) { display: flex; flex: 1; overflow: hidden; max-width: 140px;}
}

.admin-lms-users-results {
  .flex-header:nth-child(1), .flex-table-cell:nth-child(1) { display: flex; flex: 1; overflow: hidden; max-width: 100px;}
  .flex-header:nth-child(2), .flex-table-cell:nth-child(2) { display: flex; flex: 1; overflow: hidden;}
  .flex-header:nth-child(3), .flex-table-cell:nth-child(3) { display: flex; flex: 1; overflow: hidden;}
  .flex-header:nth-child(4), .flex-table-cell:nth-child(4) { display: flex; flex: 1; overflow: hidden;}
  .flex-header:nth-child(5), .flex-table-cell:nth-child(5) { display: flex; flex: 1; overflow: hidden; max-width: 100px;}
  .flex-header:nth-child(6), .flex-table-cell:nth-child(6) { display: flex; flex: 1; overflow: hidden; max-width: 230px;}
}
</style>
