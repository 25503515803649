<template>
  <div class="flex-table-row deck-result-view">
    <div class="flex-table-cell">
      <div class="result-data result-checkbox">
        <input
          :id="&quot;checkbox&quot; + result.id"
          v-model="result.selected"
          class="asset_id"
          name="tag_id"
          type="checkbox"
          :value="result.id"
        >
        <label
          class="checkbox-label"
          :for="&quot;checkbox&quot; + result.id"
        />
      </div>
    </div>
    <div class="flex-table-cell result-id">
      <div class="result-data-id result-data">
        <div>Draft: {{ result.id }}</div>
        <div>Live: {{ result.live_catalog_id || "N/A" }}</div>
      </div>
    </div>
    <div class="flex-table-cell result-apps">
      <div
        v-for="(app, i) in result.apps"
        :key="i"
        class="result-data flex-product"
      >
        <div class="table-data-size app-name">
          {{ app.internal_name }}
        </div>
      </div>
    </div>
    <div class="flex-table-cell">
      <div class="result-data action-button-cell">
        <div class="button-container">
          <edit-button
            route-name="catalog"
            :result="result"
            button-title="Edit"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import EditButton from '../../assets/shared/page_components/EditButton'

export default {
  components: {
    EditButton
  },
  props: ['result'],
  computed: {
    ...mapGetters([
      'asset'
    ])
  },
  methods: {
    ...mapMutations([
      'setAsset',
      'resetSearchResults'
    ]),
    ...mapActions([
      'searching'
    ])
  }
}
</script>
<style lang="scss">
.deck-result-view {
  .asset-action-button {
    border: 2px solid #c2c8cc;
    width: 85px !important;
    max-width: none;
    margin: 0.325rem 0;
  }
  .tag-name {
    display: inline;
  }
}
</style>
