<template>
  <div class="search-container">
    <attachment-search v-if="$router.isRoute('attachments')" />
    <topic-search v-if="$router.isRoute('topics')" />
    <item-search v-if="$router.isRoute('items')" />
    <grouping-search v-if="$router.isRoute('groupings')" />
    <module-search v-if="$router.isRoute('learning_modules')" />
    <tag-search v-if="$router.isRoute('tags')" />
    <content-source-search v-if="$router.isRoute('content-sources')" />
    <catalog-search v-if="$router.isRoute('catalogs')" />
    <catalog-page-search v-if="$router.isRoute('catalog_pages')" />
    <deck-search v-if="$router.isRoute('decks')" />
    <discussion-search v-if="$router.isRoute('discussions')" />
    <assignment-search v-if="$router.isRoute('asset_assignments')" />
    <admin-lms-users-search v-if="$router.isRoute('admin-lms-users')" />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import railsRoutes from '../../store/rails-routes'
import AttachmentSearch from '../assets/attachments/Search'
import TopicSearch from '../assets/topics/Search'
import ItemSearch from '../assets/items/Search'
import GroupingSearch from '../assets/groupings/Search'
import TagSearch from '../settings/tags/Search'
import ContentSourceSearch from '../settings/content_sources/Search'
import ModuleSearch from '../assets/learning_modules/Search'
import CatalogSearch from '../app_manager/catalogs/Search'
import CatalogPageSearch from '../app_manager/catalog_pages/Search'
import DeckSearch from '../app_manager/decks/Search'
import DiscussionSearch from '../publishing/discussions/Search'
import AssignmentSearch from '../publishing/assignments/Search'
import AdminLmsUsersSearch from '../admin/lms-users/Search.vue'

export default {
  components: {
    AttachmentSearch,
    ItemSearch,
    TopicSearch,
    GroupingSearch,
    TagSearch,
    ContentSourceSearch,
    ModuleSearch,
    DeckSearch,
    DiscussionSearch,
    AssignmentSearch,
    CatalogSearch,
    CatalogPageSearch,
    AdminLmsUsersSearch
  },
  computed: {
    ...mapGetters([
      'assetType',
      'currentUser',
      'cmsUsers',
      'searchAssetType'
    ])
  },
  created () {
    this.setSearchAssetType({ assetType: this.$route.name })
    this.resetAsset()
    this.setSearchUrl({ url: railsRoutes.api_path({ type: 'searches' }) })
  },
  methods: {
    routeName (name) {
      return this.$route.name === name
    },
    ready () {
      return this.currentUser.apps.length !== 0
    },
    ...mapMutations([
      'setAssetType',
      'setSearchAssetType',
      'setSearchUrl',
      'resetAsset',
      'selectSearchKey'
    ])
  }
}
</script>
<style lang="scss">
.search-container {
  margin-top: 40px;
}

// ALL THE AWESOME FLEXBOX STYLING HERE
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-row-item {
  display: flex
}
.flex-search-input {
  flex: 7;
}
.search-row-section {
  margin-bottom: 1.5rem;
  z-index: 1000;
}
.flex-header-section {
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: auto;
  position: sticky;
  top: 0;
  background-color: $white-1;
  z-index: 99999;
}
.flex-header {
  line-height: 2.5rem;
  &:last-child {
    border: 0;
  }
  span {
    font-weight: bold;
    padding-left: 1rem;
    text-align: left;
    flex-wrap: wrap;
  }
}
.main-checkbox > div {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  input {
    margin: 0;
  }
}
.action-button-cell {
  height: 100%;
}
.result-data {
  display: flex;
  flex-direction: column;
  word-break: break-word;
  overflow: hidden;
  padding: 1rem;
  &.result-data-id {
    text-align: left;
    flex-wrap: nowrap;
  }
}
.result-data-id {
  min-width: 4rem;
}
.result-checkbox {
  padding: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
