<template>
  <div class="flex-header-section">
    <select-all-search-header class="select-all flex-header main-checkbox" />
    <div class="flex-header result-data-id">
      <span>ID</span>
    </div>
    <div class="flex-header">
      <span>Title</span>
    </div>
    <div class="flex-header">
      <span>Products</span>
    </div>
    <div class="flex-header">
      <span>Actions</span>
    </div>
  </div>
</template>

<script>
import SelectAllSearchHeader from '../../assets/shared/SelectAllSearchHeader'

export default {
  components: { SelectAllSearchHeader }
}
</script>

<style lang="scss" scoped>

</style>
