<template>
  <div class="flex-table-row discussion-result-view">
    <div class="flex-table-cell">
      <div class="result-data result-checkbox">
        <input
          :id="&quot;checkbox&quot; + result.id"
          v-model="result.selected"
          class="asset_id"
          name="tag_id"
          type="checkbox"
          :value="result.id"
        >
        <label
          class="checkbox-label"
          :for="&quot;checkbox&quot; + result.id"
        />
      </div>
    </div>
    <div class="flex-table-cell result-id">
      <div class="result-data-id result-data">
        <router-link
          :to="{ name: routeName(result.asset_type), params: {id: result.asset_id} }"
          target="_blank"
        >
          {{ getQid(result) }}
        </router-link>
      </div>
    </div>
    <div class="flex-table-cell result-status">
      <div class="result-data">
        <div class="result-status-text tag-name">
          Status: <span class="discussion-message-status">{{ result.status }}</span>
        </div>
      </div>
    </div>
    <div class="flex-table-cell result-email">
      <div class="result-data">
        <div
          class="tag-name"
          v-html="highlight_search_text(result.email, 'email')"
        />
      </div>
    </div>
    <div class="flex-table-cell result-message">
      <div class="result-data discussion-cell">
        <div
          class="discussion-message"
        >
          {{ result.message }}
        </div>
        <div
          class="discussion-count"
        >
          <router-link
              :to="{ name: 'discussion', params: { id: result.id } }"
              class="reply-discussion"
            >
            <discussion-svg />
            <span class="discussion-count-value">{{ result.discussion_count }}</span>
          </router-link>
        </div>
      </div>
    </div>
    <div class="flex-table-cell result-apps">
      <div
        class="result-data flex-product"
      >
        <div class="table-data-size app-name">
          {{ result.app.internal_name }}
        </div>
      </div>
    </div>
    <div class="flex-table-cell result-actions">
      <div class="result-data action-button-cell">
        <div class="button-container">
          <router-link
            :to="{ name: 'discussion', params: { id: result.id } }"
            class="reply-discussion hlt-button secondary hlt-small-button"
          >
            <div>
              View
            </div>
          </router-link>
          <button
            class="delete-discussion hlt-button warning hlt-small-button"
            @click="deleteRecord(result)"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import highlighter from '../../../mixins/highlighter.js'
import qid from '../../../mixins/qid.js'
import railsRoutes from '../../../store/rails-routes'
import DiscussionSvg from '@/assets/images/icons/comment-alt-light.svg'

export default {
  components: {
    DiscussionSvg
  },
  mixins: [
    highlighter,
    qid
  ],
  props: ['result'],
  computed: {
    ...mapGetters([
      'asset',
      'deleteAsset'
    ])
  },
  watch: {
    deleteAsset (val, oldVal) {
      if ((val === true) && (this.asset.id == this.result.id)) {
        this.delete({ record: this.asset, url: railsRoutes.api_path({ type: 'discussions' }) }).then(response => {
          this.$notify({ title: 'Message Deleted', type: 'success' })
          this.$modal.hide('delete-modal')
          this.setDeleteAsset({ value: false })
          this.resetSearchResults()
          this.searching()
        })
      }
    }
  },
  methods: {
    deleteRecord (record) {
      this.setAsset({ asset: record })
      this.$modal.show('delete-modal')
    },
    routeName (type) {
      let option = {
        Attachment: 'attachment',
        Topic: 'topic',
        V3Flashcard: 'item'
      }
      return option[type]
    },
    ...mapMutations([
      'setAsset',
      'resetSearchResults',
      'setDeleteAsset'
    ]),
    ...mapActions([
      'delete',
      'searching'
    ])
  }
}
</script>
<style lang="scss">
.discussion-result-view {
  .action-button-cell {
    a, button {
      margin-bottom: 10px;
    }
  }
  .tag-name {
    display: inline;
  }
  .discussion-message-status {
    font-style: italic;
  }
  .discussion-cell {
    display: flex;
    flex-direction: row;
    width: 100%;
    .discussion-message {
      flex: 6;
    }
    .discussion-count {
      flex: 1;
      text-align: right;
      color: $space-gray;
      font-size: 14px;
      font-weight: 600;
      a {
        color: $space-gray;
      }
      svg {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }
    .discussion-count-icon {
      height: 16px;
      margin-right: 4px;
    }
  }
}
</style>
