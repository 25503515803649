<template>
  <bulk-action-modal @confirmed="save()" @closed="$emit('closed')">
    <template v-slot:title>Change Content Source</template>

    <template v-slot:appliesTo>
      <span>Applies to {{ assetIds.length }} {{ assetType }}<span v-if="assetIds.length > 1">s</span></span>
    </template>

    <template v-slot:content>
      <div class="change-content-source-modal-container">
        <label for="">Content Source</label>
        <select
          v-model="content_source_id"
          class="hlt-select"
        >
          <option value="null">
            Select Content Source
          </option>
          <option
            v-for="(option, i) in contentSources"
            :key="i"
            :value="option.id"
          >
            {{ option.name }}
          </option>
        </select>
      </div>
    </template>
  </bulk-action-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import railsRoutes from '../../../../store/rails-routes'
import axios from 'axios'
import BulkActionModal from '@/components/assets/shared/bulk_actions/BulkActionModal.vue'
import BulkActionsMixin from '@/mixins/bulk-actions.js'

export default {
  data () {
    return {
      saving: false,
      content_source_id: null,
      contentSources: []
    }
  },
  mixins: [BulkActionsMixin],
  components: {
    BulkActionModal
  },
  computed: {
    ...mapGetters([
      'assetType',
      'currentUser'
    ])
  },
  created () {
    if (this.currentUser && this.currentUser.permissions.can_read_content_sources) {
      axios.get(railsRoutes.api_path({ type: 'content_sources' })).then(response => {
        this.contentSources = this._.sortBy(response.data.records, ['name'])
      })
    }
  },
  methods: {
    save () {
      if (!this.saving) {
        // Disable save button so it can't be pressed multiple times
        this.saving = true
        var url = railsRoutes.api_path({ type: this.assetType + 's', action: 'batch_change_content_source' })

        var params = {
          asset_ids: this.assetIds,
          content_source_id: this.content_source_id
        }

        axios.post(url, params).then(response => {
          this.saving = false
          this.$modal.hide('bulk-action-modal')
          this.$notify({ title: 'Change Content Source Job Started', type: 'success' })
        })
      }
    }
  },
  mounted () {
    this.updateAssetIds()
  }

}

</script>
<style lang="scss" scoped>
.change-content-source-modal-container {
  margin: 0;
  padding: 20px;
  min-height: 300px;
  background-color: #FCFBFC;
  height: auto;
  .button {
    color: white;
  }

  > label {
    font-weight: 700;
    margin-bottom: 8px;
    font-size: 18px;
  }
  > select {
    width: 75%;
  }
}
</style>
