<template>
  <div class="center">
    <div v-if="searchedWithKeys && !keysHaveBeenModified">
      <input
        id="assets_select_all"
        v-model="selectAllModel"
        class="check-all-box"
        name="assets_select_all"
        type="checkbox"
        selector=".asset-table"
      >
      <label
        class="checkbox-label"
        for="assets_select_all"
      />
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'

export default {
  computed: {
    selectAllModel: {
      get () {
        return this.selectAll
      },
      set (value) {
        this.setSelectAll({ value: !this.selectAll })
      }
    },
    ...mapGetters([
      'selectAll',
      'searchedWithKeys',
      'keysHaveBeenModified'
    ])
  },
  methods: {
    ...mapMutations(['setSelectAll'])
  }
}
</script>
