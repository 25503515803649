import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  data () {
    return {
      assetIds: [],
      selectedAction: null
    }
  },
  computed: {
    ...mapGetters([
      'selectedSearchResults',
      'unselectedSearchResults',
      'selectAll',
      'selectedSearchKeys'
    ])
  },
  methods: {
    ...mapMutations([
      'setAsset'
    ]),
    ...mapActions([
      'getSearchResults'
    ]),
    async selectAction(selection) {
      this.selectedAction = selection
      await this.$nextTick()
      this.$modal.show('bulk-action-modal')
    },
    closedModal () {
      this.selectedAction = null
    },
    processSelectAll (params, modal) {
      this.getSearchResults(params).then(response => {
        let unselectedAssetIds = this.unselectedSearchResults.map(x => x.id)
        this.assetIds = response.data.asset_ids.filter(x => !unselectedAssetIds.includes(x))
        if (this.$modal) {
          this.$modal.show(modal)
        }
      }).catch(error => {
        console.log(error)
        this.$notify({ title: 'Error Retrieving Results', type: 'error' })
      })
    },
    updateAssetIds(modal) {
      if ((this.selectedSearchResults && this.selectedSearchResults.length > 0) || this.selectAll) {
        this.setAsset({ asset: { product_associations: [] } })
        if (this.selectAll) {
          var params = this.selectedSearchKeys.slice()
          params.push({ key: 'pagination', value: false })

          if (this.$router.isRoute('topic') && (modal === 'add-to-product')) {
            params.push({ key: 'parent_id', value: 'null' })
          }

          this.processSelectAll(params, modal)
        } else {
          if (this.$router.isRoute('topic') && (modal === 'add-to-product')) {
            this.assetIds = this.selectedSearchResults.filter(x => x.parent_id == null).map(x => x.id)
          } else {
            this.assetIds = this.selectedSearchResults.map(x => x.id)
          }
        }
      }
    }
  }
}
