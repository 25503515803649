<template>
  <div class="inner-content">
    <div class="flex-row search-row-section">
      <div class="flex-row-item flex-search-input">
        <search-input />
      </div>
    </div>
    <div class="main-table-container">
      <search-results />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import SearchInput from '../../assets/shared/SearchInput'
import SearchResults from '../../shared/SearchResults.vue'
import searchKeys from '../../../mixins/search-keys'

export default {
  name: 'CatalogSearch',
  components: {
    SearchInput,
    SearchResults
  },
  mixins: [searchKeys],
  computed: {
    ...mapGetters([
      'asset'
    ])
  },
  async created () {
    this.setAssetType({ assetType: 'catalog' })

    const keys = [
      { key: 'ids', display_name: 'IDs', type: 'text' },
      { key: 'products', display_name: 'Product', type: 'product', hideCategories: true },
      { key: 'created_at', display_name: 'Created At', type: 'date_range' },
      { key: 'updated_at', display_name: 'Updated At', type: 'date_range' }
    ]
    this.setAvailableKeys(keys)
  },
  methods: {
    ...mapMutations([
      'setAssetType'
    ])
  }
}
</script>
<style lang="scss">
.table-container {
  border: solid 1px $grey-3;
}
</style>
