<template>
  <div class="flex-table-row grouping-result-view">
    <div class="center select-all flex-table-cell">
      <div class="result-data result-checkbox">
        <input
          :id="&quot;checkbox&quot; + result.id"
          v-model="result.selected"
          class="asset_id"
          name="grouping_id"
          type="checkbox"
          :value="result.id"
        >
        <label
          class="checkbox-label"
          :for="&quot;checkbox&quot; + result.id"
        />
      </div>
    </div>
    <div class="flex-table-cell result-id">
      <div class="result-data-id result-data">
        <div>Draft: {{ result.id }}</div>
        <div>Live: {{ result.live_grouping_id || "N/A" }}</div>
      </div>
    </div>
    <div class="flex-table-cell result-name">
      <div class="result-data table-data-size">
        <div
          class="grouping-name"
          v-html="highlight_search_text(result.name, 'name')"
        />
      </div>
    </div>
    <div class="flex-table-cell result-apps">
      <div class="result-data apps">
        <div
          v-for="(app, i) in result.apps"
          :key="i"
          class="flex-product"
        >
          <div class="table-data-size app-name">
            {{ app.internal_name | truncate(40) }}
          </div>
        </div>
      </div>
    </div>

    <div class="flex-table-cell actions">
      <div class="result-data table-data-size">
        <div class="button-container">
          <div class="asset-action-button threes">
            <div
              class="copy"
              @click="copyToClipboard({selector: '#grouping_id_' + result.id})"
            >
              <input
                :id="&quot;grouping_id_&quot; + result.id"
                type="hidden"
                :value="result.id"
                class="hidden"
              >
              <copy-svg />
            </div>
            <div
              class="meta"
              @click="metadata(result)"
            >
              <info-svg />
            </div>
            <a
              class="trash"
              href="#"
              @click="currentUser.permissions.can_delete_groupings && deleteRecord(result)"
            >
              <trash-svg />
            </a>
          </div>
          <edit-button
            route-name="grouping"
            :result="result"
            button-title="Edit"
          />
        </div>

        <modal
          v-on-clickaway="hideMetaData"
          :name="'metadata-modal-' + result.id"
          height="auto"
          :scrollable="false"
          class="vue-modal"
          width="45%"
        >
          <div class="vue-modal-inner">
            <div class="vue-modal-topbar">
              <h4>MetaData</h4>
            </div>
            <div
              v-if="result"
              class="modal-container"
            >
              <div>
                <label>Grouping ID: {{ result.id }}</label>
              </div>
              <div>
                <asset-associations-table :asset-associations="result.asset_associations.filter(k => !k.deleted)" />
              </div>
            </div>

            <div class="vue-modal-bottombar modal-buttons">
              <button
                class="button solid-green-button modal-button"
                @click="hideMetaData()"
              >
                Close
              </button>
            </div>
          </div>
        </modal>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import railsRoutes from '../../../store/rails-routes'
import AssetAssociationsTable from '../shared/AssetAssociationsTable'
import { mixin as clickaway } from 'vue-clickaway2'
import EditButton from '../shared/page_components/EditButton'
import highlighter from '../../../mixins/highlighter.js'
import InfoSvg from '@/assets/images/icons/info.svg'
import CopySvg from '@/assets/images/icons/copy-2.svg'
import TrashSvg from '@/assets/images/icons/trash-3.svg'

export default {
  components: {
    AssetAssociationsTable,
    EditButton,
    InfoSvg,
    CopySvg,
    TrashSvg
  },
  mixins: [
    clickaway,
    highlighter
  ],
  props: ['result'],
  computed: {
    ...mapGetters([
      'asset',
      'deleteAsset',
      'currentUser'
    ])
  },
  watch: {
    deleteAsset (val, oldVal) {
      if ((val === true) && (this.asset.id == this.result.id)) {
        this.delete({ record: this.asset, url: railsRoutes.api_path({ type: 'groupings' }) }).then(response => {
          this.$modal.hide('delete-modal')
          this.setDeleteAsset({ value: false })
          this.resetSearchResults()
          this.searching()
        })
      }
    }
  },
  methods: {
    hideMetaData () {
      if (this.$modal) {
        this.$modal.hide(`metadata-modal-${this.result.id}`)
      }
    },
    metadata () {
      if (this.$modal) {
        this.$modal.show(`metadata-modal-${this.result.id}`)
      }
    },
    deleteRecord (record) {
      this.setAsset({ asset: record })
      if (this.$modal) {
        this.$modal.show('delete-modal')
      }
    },
    ...mapMutations([
      'setAsset',
      'resetSearchResults',
      'setDeleteAsset'
    ]),
    ...mapActions([
      'delete',
      'searching',
      'copyToClipboard'
    ])
  }
}
</script>
<style lang="scss" scoped>
.grouping-result-view {
  .asset-action-button {
    width: 85px !important;
    max-width: none;
    margin: 0.325rem 0;
  }
}
.prod-assoc-table {
  .flex-table-row:last-child {
    border-bottom: 0;
  }
}

.threes {
  padding: 0px !important;
  text-align: left;
  display: flex;

  img {
    fill: $grey-3;
  }
  div {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    height: 25px;
    width: 30px;
  }
  .copy {
    border-right: 2px solid $grey-3;
    padding-top: 3px;
    padding-left: 5px;
    padding-right: 5px;
    cursor: pointer;

    img {
      width: 15px;
    }
  }
  .meta {
    border-right: 2px solid $grey-3;
    padding-top: 3px;
    padding-left: 1px;
    padding-right: 5px;
    cursor: pointer;

    img {
      width: 15px;
    }
  }
  .trash {
    padding-top: 5px;
    padding-left: 3px;
    padding-right: 5px;
  }
}
.asset-action-button {
  border: 2px solid $grey-3 !important;
  width: 85px !important;
  max-width: none;
  margin: 0.325rem 0;
}
.grouping-name {
  display: inline;
}
</style>
