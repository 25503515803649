<template>
  <div class="flex-header-section">
    <select-all-search-header class="select-all flex-header main-checkbox" />
    <div class="flex-header">
      <span>ID</span>
    </div>
    <div class="flex-header">
      <span>Name</span>
    </div>
    <div class="flex-header">
      <span>Media</span>
    </div>
    <div class="flex-header">
      <span>Product</span>
    </div>
    <div class="flex-header">
      <span>Actions</span>
    </div>
  </div>
</template>
<script>
import SelectAllSearchHeader from '../shared/SelectAllSearchHeader'

export default {
  components: { SelectAllSearchHeader }
}
</script>
